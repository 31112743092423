import React from "react";
import moment from "moment";
import classes from "./MainReceipt.module.scss";
import { useSelector } from "react-redux";
import { useGetActiveLocation } from "hooks/useGetActiveLocation";
import { useLocation } from "react-router-dom";
import { getCartItemTotalWithTax, getItemSubTotalWithTax } from "utillity/utils";
import { useGetActiveLocationWithOrderTab } from "hooks/useTaxHooks";

const ReceiptBuilder = ({
  item,
  orderSplits,
  isDuplicate,
  duplicateSplits,
  logo,
  restaurant,
  currentLocation,
  order,
  showTax,
  getSubTotalPrice,
  getTotalTax,
  showDiscount,
  getDiscount,
  showChannel,
  getChannelPrice,
  getTotalPrice,
  isPaid,
  qrCode,
  showTenderTax,
  tenderFee,
  orderCreatedAt,
  splitBillFinalAmount,
  receiptType,
}) => {
  const { checkoutChannel } = useSelector((state) => state.channel);
  const { orderTaxes, selectedOrders } = useSelector((state) => state.editOrder);
  const { checkoutTender } = useSelector((state) => state.tender);
  const cartState = useSelector((state) => state.cart);

  const cashierName = localStorage.getItem("cashier_name") || "Admin";
  const cartDineInOrder = selectedOrders.find((order) => order.type == "dine in");

  const { notes, decimal_preference, arabic_notes } = useGetActiveLocation();
  const { currency } = useGetActiveLocationWithOrderTab({
    checkoutChannel,
  });
  const activeLocation = useLocation();
  const staffReceipt = "staffReceipt";

  const validDuplicateSplits = Array.isArray(duplicateSplits) ? duplicateSplits : [];

  const getTotalTaxAmount = () => {
    if (!showTax || !currentLocation?.taxes) return 0;
    
    const baseAmount = getSubTotalPrice();
    return currentLocation.taxes.reduce((total, tax) => {
      const taxAmount = (baseAmount * tax.tax_value) / 100;
      return total + Number(taxAmount.toFixed(decimal_preference));
    }, 0);
  };

  const getTotalTenderTax = () => {
    let tenderTaxTotal = 0;
    
    if (validDuplicateSplits.length > 0) {
      tenderTaxTotal = validDuplicateSplits
        .map((split) => Number(split.tender_tax_applied) || 0)
        .reduce((a, b) => a + b, 0);
    } else if (orderSplits && orderSplits.length > 0) {
      tenderTaxTotal = orderSplits
        .map((split) => Number(split.tender_tax_applied) || 0)
        .reduce((a, b) => a + b, 0);
    }

    if (showTenderTax && tenderFee) {
      tenderTaxTotal += Number(tenderFee);
    }

   
    return tenderTaxTotal;
  };

  if (item?.field === "duplicate" && isDuplicate && item?.is_active === 1) {
    return (
      <div className="w-full mb-4 ">
        <div className="w-full flex flex-row items-center justify-center">
          <span className="text-[1.2rem]  font-sans text-[#000] ">Duplicate Printed On</span>

          <span className="text-[1.2rem] font-sans text-[#000] leading-6 capitalize">
            &nbsp;{moment().format("hh:mm:ss a DD/MM/yyyy")}
          </span>

     
        </div>
      </div>
    );
  } else if (item?.field === "logo" && item?.is_active === 1) {
    return (
      <div className="w-full flex flex-col items-center justify-center">
        <img src={logo} alt="Company Logo" height={50} width={50} className="block mb-4  z-50" />
      </div>
    );
  } else if (item?.field === "restaurant_name" && restaurant && item?.is_active === 1) {
    return (
      <p className={`text-center ${classes.RestaurantNameLg1}`}>
        {restaurant?.translations?.en?.name}
      </p>
    );
  } else if (item?.field === "outlet_name" && restaurant && item?.is_active === 1) {
    return (
      <div className="w-full flex flex-col items-center justify-center">
        <p className={`text-center ${classes.BranchNameLg1}`}>
          {currentLocation?.translations?.en?.name}
        </p>
        <p className={classes.RestaurantNameLg2}>{restaurant?.translations?.ar?.name}</p>
      </div>
    );
  } else if (item?.field === "outlet_phone" && item?.is_active === 1) {
    return <p className={`text-center ${classes.BranchPhone}`}>{currentLocation?.phone}</p>;
  } else if (item?.field === "outlet_email" && item?.is_active === 1) {
    return <p className={`text-center ${classes.BranchPhone}`}>{currentLocation?.email}</p>;
  } else if (
    item?.field === "tax_no" &&
    restaurant &&
    restaurant?.tax_no &&
    item?.is_active === 1
  ) {
    return (
      <div className="w-full flex flex-col items-center justify-center">
        <p className={classes.VAT}>VAT : {restaurant?.tax_no || ""}</p>
        <p className={classes.VAT_AR}>{restaurant?.tax_no || ""} : ضريبة القيمة المضافة </p>
      </div>
    );
  } else if (
    item?.field === "body_message" &&
    currentLocation &&
    currentLocation?.body_message &&
    item?.is_active === 1
  ) {
    return (
      <div className="w-full mt-5 text-center">
        <p className="text-[1.1rem] font-sans text-[#000] text-center">
          {currentLocation?.body_message}
        </p>
      </div>
    );
  } else if (item?.field === "invoice_number" && item?.is_active === 1) {
    return (
      <div className="w-full flex flex-col items-center justify-center">
        {receiptType === staffReceipt ? null : (
          <div className={classes.TaxText}>
            <span className={classes.TaxTextLg1}>Simplified Tax Invoice</span>
            <span className={classes.TaxTextLg2}>فاتورة ضريبية مبسطة</span>
          </div>
        )}
        <p className={classes.Id}>{order?.order_calling_number || ""} # الطلب</p>
        {receiptType === staffReceipt ? (
          <span className="text-[1.2rem] font-sans text-[#000] leading-6 capitalize">
            Date: &nbsp;{moment().format("hh:mm:ss a DD/MM/yyyy")}
          </span>
        ) : null}
      </div>
    );
  } else if (
    item?.field === "check_id" &&
    order &&
    item?.is_active === 1
    // &&
    // order?.check_id &&
    // item?.is_active === 1
  ) {
    return (
      <div className="flex flex-row items-center">
        {isDuplicate && orderCreatedAt ? (
          <span className={`text-[1.1rem] text-[#000] ${classes.mainFont}`}>
            {moment(orderCreatedAt).format("hh:mm:ss a DD/MM/yyyy")}
          </span>
        ) : (
          <span className={`text-[1.1rem] text-[#000] ${classes.mainFont}`}>
            {moment(order?.created_at).format("hh:mm:ss a DD/MM/yyyy")}
          </span>
        )}

        {/* <span className={`text-[1.1rem] text-gray-600 ${classes.mainFont}`}>
          Check ID: {order?.check_id}hello
        </span> */}
      </div>
    );
  } else if (
    item?.field === "order_completion" &&
    order &&
    order?.order_no &&
    item?.is_active === 1
  ) {
    return (
      <div className="flex flex-row items-center">
        <span className={`text-[1.1rem] text-[#000] ${classes.mainFont}`}>#{order?.order_no}</span>
      </div>
    );
  } else if (item?.field === "customer_name" && order && item?.is_active === 1) {
    return (
      <div className="flex flex-row items-center my-2">
        <span className={`text-[1.1rem] text-[#000] ${classes.mainFont}`}>
          Guest Name: {order?.client_name}
        </span>
      </div>
    );
  } else if (
    item?.field === "customer_phone" &&
    order &&
    order?.client_phone &&
    item?.is_active === 1
  ) {
    return (
      <div className="flex flex-row items-center my-2">
        <span className={`text-[1.1rem] text-[#000] ${classes.mainFont}`}>
          Guest Name: {order?.client_phone}
          helo
        </span>
      </div>
    );
  } else if (item?.field === "service" && item?.is_active === 1) {
    return (
      <>
        {receiptType === staffReceipt ? (
          <div className="flex flex-row items-center">
            <span className={`text-[1.4srem] text-[#000] mx-auto block ${classes.mainFont}`}>
              *** <span className="uppercase">{cartState?.activeCart}</span> Order ***
            </span>
          </div>
        ) : (
          <>
            {order && (
              <div className="flex flex-row items-center">
                <span className={`text-[1.1rem] text-[#000] capitalize ${classes.mainFont}`}>
                  Service: {order?.order_type}
                </span>
              </div>
            )}
          </>
        )}
      </>
    );
  } else if (
    item?.field === "table_no" &&
    receiptType === staffReceipt &&
    cartState?.activeCart === "dine in" &&
    item?.is_active === 1
  ) {
    return (
      <div className="flex flex-row items-center">
        <span className={`text-[1.1rem] text-[#000] ${classes.mainFont}`}>
          Table: {cartDineInOrder && cartDineInOrder.outletTable.table_number}
        </span>
      </div>
    );
  } else if (
    item?.field === "table_no" &&
    order &&
    order?.order_type === "dine in" &&
    item?.is_active === 1
  ) {
    return (
      <div className="flex flex-row items-center">
        <span className={`text-[1.1rem] text-[#000] ${classes.mainFont}`}>
          Table: {order?.outletTable?.table_number}
        </span>
      </div>
    );
  } else if (item?.field === "channel_name" && order && item?.is_active === 1 && showChannel) {
    return (
      <div className="flex flex-row items-center">
        {!isDuplicate ? (
          <span className={`text-[1.1rem] text-[#000] ${classes.mainFont}`}>
            Channel: {checkoutChannel?.name}
          </span>
        ) : (
          <span className={`text-[1.1rem] text-[#000] ${classes.mainFont}`}>
            Channel: {order?.delivery_channel?.name}
          </span>
        )}
      </div>
    );
  }
  
  else if (item?.field === "items" && item?.is_active === 1) {
    return (
      <>
        {receiptType === staffReceipt && (
          <>
            <div className="text-[1.1rem] font-sans text-[#000] mb-4">Station: Kitchen</div>
            <div className="text-[1.1rem] font-sans text-[#000] mb-4">
              Server Name: {cashierName || ""}
            </div>
          </>
        )}
        <div className="w-full mt-4 mb-4 border-t border-t-gray-300 pt-5">
          <table className={`${classes.ReceiptTable} receipt-table`}>
            <thead className="">
              <th>Item Name</th>
              <th>QT</th>
              {receiptType === staffReceipt ? null : (
                <>
                  <th>Price</th>
                  {showTax && <th>Tax</th>}
                  <th>Subtotal</th>
                </>
              )}
            </thead>
            <tbody>
              {Object.keys(cartState?.cart).length > 0 &&
                cartState?.cart[cartState?.activeCart]?.map((item, index) => {
                  return (
                    <tr
                      key={index}
                      className={`${
                        receiptType === staffReceipt && index > 0
                          ? "border-t border-t-gray-300"
                          : ""
                      }`}
                    >
                      <td
                        className={`${classes.ItemNameCell} ${
                          receiptType === staffReceipt ? "my-3" : ""
                        }`}
                      >
                        <span className={item.isRemoved ? "text-red-400" : ""}>
                          {item?.item_name}
                        </span>
                        <p className="text-right m-0">{item?.translations?.ar?.name}</p>
                        {receiptType === staffReceipt && item.notes ? (
                          <span
                            className="whitespace-pre-line"
                            style={{ fontSize: "10px", margin: "5px 0px" }}
                          >
                            {item.notes}
                          </span>
                        ) : null}
                      </td>
                      <td className={`text-2xl ${receiptType === staffReceipt ? "my-3" : ""}`}>
                        <p className={`${receiptType === staffReceipt} ? "pt-4" : ""`}>
                          {item?.quantity}
                        </p>
                      </td>
                      {receiptType === staffReceipt ? null : (
                        <>
                          <td>{item?.price.toFixed(decimal_preference)}</td>
                          {showTax && (
                            <td>{Number(Number(item?.order_vat)).toFixed(decimal_preference)}</td>
                          )}
                          <td>
                            {getItemSubTotalWithTax(
                              item?.quantity,
                              item?.price,
                              item?.order_vat
                            ).toFixed(decimal_preference)}
                          </td>
                        </>
                      )}
                    </tr>
                  );
                })}
            </tbody>
          </table>
          <div className="w-full h-[1px] bg-gray-300 " />
          {receiptType === staffReceipt && selectedOrders[0]?.comment.trim() && (
            <div className="mt-8">
              <div className="text-3xl">Notes: </div>
              <div className="w-full felx flex-col text-left">
                <p className="text-[1.1rem] font-sans text-[#000] whitespace-pre-line">
                  {selectedOrders[0]?.comment}
                </p>
              </div>
            </div>
          )}
        </div>
      </>
    );
  } else if (item?.field === "price_without_vat" && item?.is_active === 1) {
    return (
      <div className="w-full flex flex-row items-center justify-between pb-3">
        <span className="text-[1.2rem]  font-sans text-[#000]">Price without Tax</span>
        <span className="text-[1.2rem] font-sans text-[#000] leading-6">
          {currency}&nbsp;
          {getSubTotalPrice().toFixed(decimal_preference)}
        </span>
      </div>
    );
  } else if (item?.field === "vat_value" && showTax && item?.is_active === 1) {
    const baseAmount = getSubTotalPrice();
    const regularTax = getTotalTaxAmount();
    const tenderTax = getTotalTenderTax();
    
    const priceWithTax = Math.round((baseAmount + regularTax + tenderTax) * 100) / 100;
    
    
    
    return (
      <div className="w-full">
        {/* Regular tax calculations */}
        {showTax && currentLocation?.taxes?.length > 0 && currentLocation?.taxes?.map((tax) => {
          const taxAmount = (baseAmount * tax.tax_value) / 100;
          
          return (
            <div key={tax.id} className="w-full flex flex-row items-center justify-between pb-3">
              <span className="text-[1.2rem] font-sans text-[#000] capitalize">
                {tax?.translations?.en?.name} ({tax.tax_value}%)
              </span>
              <span className="text-[1.2rem] font-sans text-[#000] leading-6">
                {currency}&nbsp;
                {Number(taxAmount).toFixed(2)}
              </span>
            </div>
          );
        })}

        {/* Tender Tax Display */}
        {showTenderTax && tenderFee > 0 && (
          <div className="w-full flex flex-row items-center justify-between pb-3">
            <span className="text-[1.2rem] font-sans text-[#000]">Tender</span>
            <span className="text-[1.2rem] font-sans text-[#000] leading-6">
              {currency}&nbsp;
              {Number(tenderFee).toFixed(decimal_preference)}
            </span>
          </div>
        )}

        {/* Price With Tax */}
        {showTax && (
          <div className="w-full flex flex-row items-center justify-between pb-3">
            <span className="text-[1.2rem] font-sans text-[#000]">Price With Tax</span>
            <span className="text-[1.2rem] font-sans text-[#000] leading-6">
              {currency}&nbsp;
              {priceWithTax.toFixed(2)}
            </span>
          </div>
        )}
      </div>
    );
  } 
  else if (item?.field === "tender_tax" && item?.is_active === 1) {
    return (
      <div className="w-full">
        {validDuplicateSplits.length > 0 && validDuplicateSplits.map((split, index) => (
          <div key={index} className="w-full flex flex-row items-center justify-between pb-3">
            <span className="text-[1.2rem] font-sans text-[#000]">
              Tender {split.tender_name ? `(${split.tender_name})` : ""} Tax
            </span>
            <span className="text-[1.2rem] font-sans text-[#000] leading-6">
              {currency} {split.tender_tax_applied}
            </span>
          </div>
        ))}
        {orderSplits && orderSplits.length > 0 && orderSplits.map((split, index) => (
          <div key={index} className="w-full flex flex-row items-center justify-between pb-3">
            <span className="text-[1.2rem] font-sans text-[#000]">
              Tender {split.tender_name ? `(${split.tender_name})` : ""} Tax
            </span>
            <span className="text-[1.2rem] font-sans text-[#000] leading-6">
              {currency} {split.tender_tax_applied}
            </span>
          </div>
        ))}
      </div>
    );
  } 
  else if (item?.field === "total_discount" && showDiscount && item?.is_active === 1) {
    return (
      <div className="w-full">
        {showDiscount && (
          <div className="w-full flex flex-row items-center justify-between pb-3">
            <span className="text-[1.2rem]  font-sans text-[#000]">Discount</span>

            <span className="text-[1.2rem] font-sans text-[#000] leading-6">
              {currency} {getDiscount().toFixed(decimal_preference)}
            </span>
          </div>
        )}
      </div>
    );
  } else if (item?.field === "channel_commission" && showChannel && item?.is_active === 1) {
    return (
      <div className="w-full">
        {showChannel && (
          <div className="w-full flex flex-row items-center justify-between pb-3">
            <span className="text-[1.2rem]  font-sans text-[#000]">Channel</span>
            <span className="text-[1.2rem] font-sans text-[#000] leading-6">
              {currency} {getChannelPrice().toFixed(decimal_preference)}
            </span>
          </div>
        )}
        {showTenderTax && (
          <div className="w-full flex flex-row items-center justify-between pb-3">
            <span className="text-[1.2rem]  font-sans text-[#000]">Tender</span>
            <span className="text-[1.2rem] font-sans text-[#000] leading-6">
              {currency} {tenderFee.toFixed(decimal_preference)}
            </span>
          </div>
        )}
      </div>
    );
  } else if (item?.field === "no_of_products" && item?.is_active === 1) {
    return (
      <div className="w-full flex flex-row items-center justify-between pb-3 mt-3">
        <span className="text-[1.2rem]  font-sans text-[#000] ">No of products:</span>
        <span className="text-[1.2rem] font-sans text-[#000] leading-6 capitalize">
          {cartState?.cart[cartState?.activeCart].reduce((sum, item) => sum + item.quantity, 0) || 0}
        </span>
      </div>
    );
  } else if (item?.field === "amount_payable" && item?.is_active === 1) {
    const baseAmount = getSubTotalPrice();
    const regularTax = getTotalTaxAmount();
    const tenderTax = getTotalTenderTax();
    
    const total = Math.round(baseAmount + regularTax + tenderTax);
    
    

    return (
      <div className="w-full border-t border-t-gray-300">
        <div className="w-full flex flex-row items-center justify-between pb-3 mt-2">
          <span className="text-[1.2rem] font-sans text-[#000] font-bold">Payable Amount</span>
          <span className="text-[1.2rem] font-sans text-[#000] leading-6 font-bold">
            {currency}&nbsp;
            {total}
          </span>
        </div>
      </div>
    );
  } else if (item?.field === "status" && item?.is_active === 1) {
    return (
      <div className="w-full ">
        {isDuplicate && (
          <div className="w-full flex flex-row items-center justify-between pb-3 mt-2">
            <span className="text-[1.2rem]  font-sans text-[#000] ">Status:</span>

            <span className="text-[1.2rem] font-sans text-[#000] leading-6 ">Duplicate</span>
          </div>
        )}
        {!isDuplicate && activeLocation?.pathname === "/orders/checkout/receipt" && (
          <div className="w-full flex flex-row items-center justify-between pb-3 mt-2">
            <span className="text-[1.2rem]  font-sans text-[#000] ">Status:</span>

            <span className="text-[1.2rem] font-sans text-[#000] leading-6 ">
              {splitBillFinalAmount|| isPaid ? "Paid" : "Un-Paid"}
            </span>
          </div>
        )}
      </div>
    );
  } 
  else if (item?.field === "tender" && item?.is_active === 1) {

    
    return (
      <div className="w-full flex flex-row items-center justify-between">
        <span className="text-[1.2rem]  font-sans text-[#000] ">Tender:</span>
        {!isDuplicate ? (
          <span className="text-[1.2rem] font-sans text-[#000] leading-6 capitalize">
            {checkoutTender?.text || checkoutTender?.name}
          </span>
        ) : (
          <span className="text-[1.2rem] font-sans text-[#000] leading-6 capitalize">
            {validDuplicateSplits?.length > 0 ? "split bill" : order?.tender?.name}
          </span>
        )}
      </div>
    );
  }
   else if (item?.field === "bar_code" && item?.is_active === 1) {
    return null;
  } else if (item?.field === "qr" && item?.is_active === 1) {
    return (
      <div>
        <br />
        {qrCode && qrCode !== "" && (
          <div className="w-full flex flex-row items-center justify-center">
            <img src={qrCode} alt="QrCode" className="w-[80px] h-[80px]" />
          </div>
        )}
      </div>
    );
  } else if (item?.field === "footer_message" && item?.is_active === 1) {
    return (
      <footer className="w-full flex flex-col items-center justify-center mt-2">
        
        <div className="w-full  text-center">
          {notes?.split(/\r?\n/).map((item, index) => (
            <p key={index} className="text-[1.1rem] font-sans text-[#000]">
              {item}
            </p>
          ))}
        </div>
        <div className="w-full mt-2 text-center">
          <p className="text-[1.1rem] font-sans text-[#000]">{arabic_notes}</p>
        </div>
        <br />
        <br />.<div className={`mb-5 ${classes.pageBreak}`}>.</div>
      </footer>
    );
  }
  return null;
};

export default ReceiptBuilder;
