import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import styles from './SplitReceipt.module.scss';
import moment from 'moment';
import { useGetActiveLocation } from "hooks/useGetActiveLocation";

import { useSelector } from 'react-redux';
const SplitReceipt = ({
    split,
    totalAmount,
  
    checkoutOrder,
 
}) => {
    const printRef = useRef();
    
    const handlePrint = useReactToPrint({
        content: () => printRef.current,
    });
     // Access the order from the Redux state
  const order = useSelector((state) => state.editOrder.order);
  const { currentLocation } = useGetActiveLocation();
// console.log(currentLocation);
  // Use orderId and totalAmount from the Redux state

  const orderId = `#${order.order_no}`;
const getRestaurantLogo = JSON.parse(localStorage.getItem("restaurant_details"));

  return (
    <>
        <div ref={printRef} className={styles.receipt}>
            <div className={styles.header}>
                <div className={styles.logo}>
                    <img src={getRestaurantLogo?.logo} alt="logo" />
                </div>
                <div className={styles.brandInfo}>
                    <div>{getRestaurantLogo?.name || "Branch Name"}</div>

                    <div>
                    <p>{currentLocation?.translations?.en?.name}</p>
                    <p>{currentLocation?.translations?.ar?.name }</p>


                    </div>
                    
                </div>
            </div>

            <div className={styles.taxInfo}>
                <div className={styles.fieldGroup}>
                    <label>VAT:</label>
                    <div>{getRestaurantLogo?.tax_no || "VAT / Tax Number"}</div>
                </div>
                <div className={styles.invoiceTypes}>
                    <span>Simplified Tax Invoice (EN)</span>
                    <span>Simplified Tax Invoice (AR)</span>
                </div>
            </div>

            <div className={styles.orderDetails}>
                <div className={styles.fieldGroup}>
                    <label>Invoice:</label>
                    <div>{split?.invoice_number || checkoutOrder?.order_no}</div>
                </div>
                <div className={styles.fieldGroup}>
                    <label>Order Completion:</label>
                    <div>{moment().format("MM/DD/YYYY HH:mm")}</div>
                </div>
                <div className={styles.fieldGroup}>
                    <label>Order ID:</label>
                    <div>{orderId}</div>
                </div>
            </div>

            <div className={styles.paymentDetails}>
                <div className={styles.fieldGroup}>
                    <label>Total Payable Amount:</label>
                    <div>{totalAmount || "0.00"}</div>
                </div>
                <div className={styles.fieldGroup}>
                    <label>Guest:</label>
                    <div>{split?.guest_number || "Guest Serial"}</div>
                </div>
                <div className={styles.fieldGroup}>
                    <label>Amount Contributed:</label>
                    <div>{split?.final_amount || "0.00"}</div>
                </div>
                <div className={styles.fieldGroup}>
                    <label>Status:</label>
                    <div>{split?.payment_status === "Received" ? "Paid" : "Un-Paid"}</div>
                </div>
                <div className={styles.fieldGroup}>
                    <label>Tender:</label>
                    <div>{split?.tender_name || "Cash"}</div>
                </div>
            </div>

            <div className={styles.barcode}>
                {split?.barcode || "Bar Code"}
            </div>
        </div>
        
        <div className={styles.printButton}>
            <button onClick={handlePrint}>
                Print Receipt
            </button>
        </div>
    </>
  );
};

export default SplitReceipt; 