import DrawerCart from "components/DrawerCart/DrawerCart";
import DrawerCartPrimaryActionButtons from "components/DrawerCart/DrawerCartPrimaryActionButtons/DrawerCartPrimaryActionButtons";
import Loader from "components/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  addItemToCart,
  changeActiveCart,
  toggleCheckoutOrder,
} from "redux/cart";
import {
  // clearOrder,
  toggleOrderTypes,
} from "redux/createOrder";
// import { fetchCategoryDishes, updateDishStock } from "redux/dish";
import { fetchServices } from "redux/service";
import classes from "./PrimaryDrawerCart.module.scss";
import { addDuplicateOrder } from "redux/duplicateReceipt";
import SingleOrderType from "components/Cart/SingleOrderType";
import SingleListItem from "./SingleListItem";
import axiosWithLocationV2 from "config/axiosWithLocationV2";
// import { AuthContext } from "context/authContext";
import {
  changeActiveService,
  saveOrderTaxes,
  toggleActiveCheckoutOrder,
  // updateDiscountInPrimaryDrawerCart,
} from "redux/editingOrder";
import { useGetActiveLocation } from "hooks/useGetActiveLocation";
import { useDefaultSetting } from "hooks/useDefaultSetting";
import { usePermissionHooks } from "hooks/usePermissionHooks";
import { ORDER_COMPLETE_PERMISSION } from "constants/constants";
// import { ORDER_COMPLETE_PERMISSION } from "constants/constants";
// import { usePermissionHooks } from "hooks/usePermissionHooks";
import { useReactToPrint } from "react-to-print";
import MainReceipt from "components/receipt/MainReceipt";
import Icon from "components/Icon/Icon";
import VerifyPinModal from "components/Modal/VerifyPinModal/VerifyPinModal";

const PrimaryDrawerCart = ({
  onProceed = () => {},
  onEdit = () => {},
  order,
  toggleDrawer,
  clearAll,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showPinModal, setShowPinModal] = useState(false);
  const [modalType, setModalType] = useState("");
  const { error: categoryError } = useSelector((state) => state.category);
  const { error: dishError } = useSelector((state) => state.dish);
  const cartState = useSelector((state) => state.cart);
  const { orderTypes: cartTypes } = useSelector((state) => state.createOrder);
  const [orderTypes, setOrderTypes] = useState([]);
  const { translations: servicesTranslations } = useSelector(
    (state) => state.service
  );
  const { selectedOrders, activeService } = useSelector(
    (state) => state.editOrder
  );
  const [activeTab, setActiveTab] = useState({});
  const { pos_pin_status, currency } = useGetActiveLocation();
  const { paymentChannelDestination } = useDefaultSetting();
  const canCompleteOrder = usePermissionHooks(ORDER_COMPLETE_PERMISSION);
  const receiptRef = useRef();

  // Changing active tab state
  const radioChangeHandler = (newValue) => {
    dispatch(changeActiveCart(newValue));
  };

  /**
   * @function To get the active tab total price
   * @param It takes the active order tab
   */
  const getActiveTabTotalPrice = (val) => {
    let price = 0;
    const currentTab = Object.keys(cartState?.cart);
    currentTab.forEach((el) => {
      const currentOrderArray = cartState?.cart[val];
      // If the length of currentOrderArray is greater than 1
      if (currentOrderArray?.length >= 1) {
        if (val === el) {
          for (const cartItem of cartState?.cart[val] || []) {
            const item_price =
              +(cartItem?.price + cartItem?.vat) * cartItem?.quantity;

            // Calculate the discounted value for the current item
            const discountedValue =
              cartItem.discount_type === "percentage"
                ? item_price -
                  (item_price * Number(cartItem.discount_value ?? 0)) / 100
                : item_price - Number(cartItem.discount_value ?? 0);
            // Add the discounted value to the total price
            price += discountedValue;
          }
        }
      } else {
        price = 0;
      }
    });

    const activeOrderDiscount = selectedOrders.find(
      (orderItem) => orderItem.type === val
    );
    const priceAfterDiscount =
      Number(activeOrderDiscount?.discount_value) > 0
        ? activeOrderDiscount?.discount_type === "percentage"
          ? price - (price * Number(activeOrderDiscount?.discount_value)) / 100
          : price - Number(activeOrderDiscount?.discount_value)
        : price;

    return priceAfterDiscount.toFixed(3);
  };

  /* Clear errors */
  useEffect(() => {
    clearAll();
  }, [clearAll]);

  const handleModal = async (type) => {
    if(pos_pin_status){
      setModalType(type);
      setShowPinModal(true);
    } else{
      if(type === "edit"){
        handleEditOrder();
      } else if (type === "checkout") {
        handleProceedOrder();
      }
    }
  };
  
  const onEditHandler = () => handleModal("edit");
  const onProceedHandler = () => handleModal("checkout");

  const handleEditOrder = async () => {
    if (cartTypes.length === 0) {
      const orderTypes = selectedOrders.map((item) => item.type);
      for (const arr of orderTypes) {
        dispatch(toggleOrderTypes(arr));
      }
    }

    await dispatch(fetchServices());
    setIsLoading(false);
    onEdit();
  };

  const onPinVerification = (isPinVerified) => {
    if (isPinVerified) {
      if (modalType === "edit") {
        handleEditOrder();
      } else if (modalType === "checkout") {
        handleProceedOrder();
      }
    }
  };

  useEffect(() => {
    if (selectedOrders) {
      const active = selectedOrders?.filter(
        (item) => item.type === activeService
      );
      setOrderTypes(selectedOrders);
      setActiveTab(active[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrders]);

  // console.log(activeTab, "Aactive", activeService);
  /**
   * @function this useEffect runs to set the necessary cart details
   */

  const loadOrdersAndFormat = useCallback(async () => {
    await dispatch(fetchServices());
    /**
     * Setting the active order to view in the cart based on the active service or selected orders
     * */
    if (activeService && activeService !== null) {
      dispatch(changeActiveCart(activeService));
    }
    // else if (activeService && activeService !== selectedOrders[0]?.type) {
    //   dispatch(changeActiveCart(selectedOrders[0]?.type));
    // }
    else {
      dispatch(changeActiveCart(selectedOrders[0]?.type));
    }
    if (order && order.uuid) {
      // setIsLoading(true);
      let activeOrder = order;
      // Get all the dishes with the uuid
      const mainOrders = await axiosWithLocationV2?.post("/order/show", {
        uuid: order.uuid,
      });
      if (!mainOrders) {
        setError("An error occurred ");
        setIsLoading(false);
      }
      //  Set the dish types

      const networkData = mainOrders.data.data.data;
      // networkData = mainOrders ? Object.values(mainOrders.data.data.data) : [];
      const mainOrderTypes = selectedOrders.map((item) => item.type);

      const categoryIds = new Set();
      const itemIds = new Set();
      const itemsWithUsedStock = {};
      for (const arr of mainOrderTypes) {
        dispatch(toggleOrderTypes(arr));
        dispatch(
          saveOrderTaxes({ service: arr, value: networkData["order_tax"][arr] })
        );
        for (const orderItem of networkData[arr]) {
          // dispatch(
          //   updateDiscountInPrimaryDrawerCart({
          //     discount: orderItem?.discount,
          //     discount_type: orderItem?.discount_type,
          //     type: arr,
          //   })
          // );
          categoryIds.add(orderItem.category_id);
          itemIds.add(orderItem.item.id);
          if (itemsWithUsedStock[orderItem.item.id] === undefined) {
            itemsWithUsedStock[orderItem.item.id] = {
              id: orderItem.item.id,
              cat_id: orderItem.category_id,
              usedStock: orderItem.quantity,
              quantity: orderItem.quantity,
            };
          } else {
            itemsWithUsedStock[orderItem.item.id].usedStock +=
              orderItem.quantity;
          }
          const item = {
            category: { id: orderItem.category_id, name: "" },
            order_item_id: orderItem.id ?? "",
            id: orderItem.item.id,
            name: orderItem.item.name,
            price: Number(orderItem.item.price),
            quantity: orderItem.quantity,
            amount: orderItem.amount,
            order_id: orderItem?.order_id,
            uuid: activeOrder.uuid,
            sizes: orderItem?.item?.sizes,
            translations: orderItem?.item?.translations,
            vatvalue: orderItem?.item?.vat,
            order_vat: orderItem?.vat,
            total_discount: Number(orderItem?.total_discount),
            discount_value: orderItem?.discount,
            discount_type: orderItem?.discount_type,
            notes:
              orderItem?.notes && orderItem?.notes === null
                ? ""
                : orderItem?.notes,
          };

          await dispatch(addItemToCart({ ...item, cartType: arr }));
        }
      }
      setIsLoading(false);
      // Change Active Cart
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order, dispatch]);

  useEffect(() => {
    loadOrdersAndFormat();
  }, [loadOrdersAndFormat]);
  const activeCheckoutOrder = () => {
    const orderFromSelected = selectedOrders.filter(
      (item) => item?.type === cartState?.activeCart
    );
    dispatch(
      toggleCheckoutOrder({
        type: cartState?.activeCart,
        orderItems: cartState?.cart
          ? cartState?.cart[cartState?.activeCart]
          : [],
        status: orderFromSelected[0]?.status,
        order_no: orderFromSelected[0]?.order_no,
        id: orderFromSelected[0]?.id,
        outlet_table_id: orderFromSelected[0]?.outlet_table_id,
        vatvalue: orderFromSelected[0]?.vatvalue,
        order_price: orderFromSelected[0]?.order_price,
        total: orderFromSelected[0]?.total,
        discount_type: orderFromSelected[0]?.discount_type,
        discount_value: orderFromSelected[0]?.discount_value,
        outletTable: orderFromSelected[0]?.outletTable,
        client_name: orderFromSelected[0]?.client_name,
        created_at: orderFromSelected[0]?.created_at,
      })
    );
    dispatch(
      toggleActiveCheckoutOrder({
        type: cartState?.activeCart,
        orderItems: cartState?.cart
          ? cartState?.cart[cartState?.activeCart]
          : [],
        status: orderFromSelected[0]?.status,
        order_no: orderFromSelected[0]?.order_no,
        id: orderFromSelected[0]?.id,
        outlet_table_id: orderFromSelected[0]?.outlet_table_id,
        vatvalue: orderFromSelected[0]?.vatvalue,
        order_price: orderFromSelected[0]?.order_price,
        total: orderFromSelected[0]?.total,
        discount_type: orderFromSelected[0]?.discount_type,
        discount_value: orderFromSelected[0]?.discount_value,
        outletTable: orderFromSelected[0]?.outletTable,
        client_name: orderFromSelected[0]?.client_name,
        created_at: orderFromSelected[0]?.created_at,
      })
    );
  };
  /**
   * Rember to clean this to use the useDefault setting on dev branch
   *
   * @Function This is used to navigate to the tender screen and save the order ID for the checkout order to get the correct taxes from BOH
   */
  // This is passed to the payment channel screen url to get the tender and channel taxes for the check out order

  // const checkoutOrderId = useMemo(() => {
  //   return selectedOrders.filter(
  //     (item) => item?.type === cartState?.activeCart
  //   )[0]?.id;
  // }, [cartState?.activeCart, selectedOrders]);

  const handleProceedOrder = async () => {
    setIsLoading(true);
    loadOrdersAndFormat();

    if (activeTab?.status === "complete") {
      dispatch(addDuplicateOrder(order));
      navigate("/orders/checkout/duplicate-receipt");
    } else {
      if (!canCompleteOrder.canView) {
        toast.error("You do not have permission to proceed to checkout!", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
        return;
      }
      paymentChannelDestination();
      // onProceed();
      activeCheckoutOrder();
      dispatch(changeActiveService(cartState?.activeCart));
    }
  };

  const isDiscountApplied = () => {
    const activeOrderDiscounts = selectedOrders.find(
      (item) => item.type === cartState.activeCart
    );
    const activeDiscountValue = activeOrderDiscounts?.discount_value ?? 0;

    return {
      amount: Number(activeDiscountValue),
      type: activeOrderDiscounts?.discount_type,
    };
  };

  const discountedValue = isDiscountApplied();

  const handlePrint = useReactToPrint({
    content: () => receiptRef.current,
  });

  return (
    <Fragment>
      <VerifyPinModal
        show={showPinModal}
        setShowPinModal={setShowPinModal}
        onPinVerification={onPinVerification}
      />
      <DrawerCart
        id={activeTab?.order_no}
        createdAt={order?.created_at}
        onBackgrondClick={toggleDrawer}
        status={activeTab?.status}
        activeCart={cartState && cartState?.activeCart}
      >
        {isLoading && !dishError && !categoryError && <Loader />}
        {(error || dishError || categoryError) && (
          <p className={classes.Error}>{error}</p>
        )}
        {!isLoading &&
          !error &&
          !dishError &&
          !categoryError &&
          Object.keys(cartState?.cart)?.length > 0 && (
            <Fragment>
              {/* BUTTON CONTROLS */}
              {/* Order Types */}
              <div className="w-full grid grid-cols-3 gap-5 pt-4 ">
                {orderTypes &&
                  orderTypes.map((type) => {
                    return (
                      <SingleOrderType
                        key={type?.type}
                        textLg1={type.type}
                        value={type.type}
                        textLg2={servicesTranslations?.ar[type.type] || ""}
                        isChecked={cartState?.activeCart === type?.type}
                        onChanged={(val) => {
                          setActiveTab(type);
                          radioChangeHandler(val);
                          // activeCheckoutOrder();
                        }}
                        totalCartPrice={getActiveTabTotalPrice(type?.type)}
                        active={cartState?.activeCart}
                      />
                    );
                  })}
              </div>
              {/* List Item */}
              <div className={classes.PriceList}>
                {cartState?.cart &&
                  cartState?.cart[cartState?.activeCart]?.map((item, index) => {
                    return (
                      <SingleListItem
                        item={item}
                        key={Math.random(100)}
                        id={item.item_id}
                        index={index + 1}
                        quantity={item.quantity}
                        price={item.price + item.vat}
                        sizes={item?.sizes}
                        translations={item?.translations}
                        name={item?.item_name}
                      />
                    );
                  })}
              </div>

              <div style={{ display: "none" }}>
                <div ref={receiptRef}>
                  <MainReceipt type="staffReceipt" />
                </div>
              </div>

              {discountedValue.amount > 0 ||
                (order.status !== "complete" && (
                  <div className="w-full mb-10 bg-[#FFF3EB] rounded py-4 flex flex-row items-center justify-between px-4">
                    {order.status !== "complete" && (
                      <div
                        onClick={() =>
                          setTimeout(() => {
                            handlePrint();
                          }, 1000)
                        }
                        className="font-Inter flex text-xl text-white bg-[#463E38] px-3 py-2 rounded-md font-medium cursor-pointer ml-2 text-right"
                      >
                        <span className="mr-2">
                          <Icon name={"print"} width={1.8} height={1.8} />
                        </span>
                        Print KOT
                      </div>
                    )}
                    {discountedValue.amount > 0 ? (
                      <span className="flex items-center">
                        <span className="mr-3">
                          <Icon name={"tick-circle"} width={1.8} height={1.8} />
                        </span>
                        {discountedValue.type === "fixed" && currency}{" "}
                        {discountedValue.amount || 0}
                        {discountedValue.type === "percentage" && "%"}
                        <span className="ml-2">Discount Added</span>
                      </span>
                    ) : null}
                  </div>
                ))}
              {!["complete", "cancel"].includes(activeTab?.status) && (
                <DrawerCartPrimaryActionButtons
                  onProceed={onProceedHandler}
                  onEdit={onEditHandler}
                  proceedDisabled={!canCompleteOrder.canView}
                />
              )}
              {activeTab?.status === "complete" && (
                <DrawerCartPrimaryActionButtons
                  onProceed={onProceedHandler}
                  text="Void Order"
                  textTwo="Print Invoice"
                  icon="tick-circle"
                  iconTwo="print"
                />
              )}
            </Fragment>
          )}
      </DrawerCart>
    </Fragment>
  );
};

export default PrimaryDrawerCart;
