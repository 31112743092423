import axiosWithLocation from "config/axiosWithLocationV2";

export const ResturantService = {
  getResturantInfo: function (success, fail) {
    axiosWithLocation.get(`/restaurant`).then(success).catch(fail);
  },
  lockScreen: function (data, success, fail) {
    axiosWithLocation.post("/screen/lock", data).then(success).catch(fail);
  },
  unLockScreen: function (data, success, fail) {
    axiosWithLocation.post("/screen/unlock", data).then(success).catch(fail);
  },
  verifyPin: function (data, success, fail) {
    axiosWithLocation.post("/verify/pin", data).then(success).catch(fail);
  },
  chooseDefaultServiceType: function (data, success, fail) {
    axiosWithLocation
      .post("/outlet/preferences", data)
      .then(success)
      .catch(fail);
  },
};
