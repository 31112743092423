import Icon from "components/Icon/Icon";
import classes from "./PaymentChannelCard.module.scss";

const SplitBillCard = ({ isSelected, onClicked }) => {
  return (
    <li
      className={
        isSelected
          ? classes.PaymentSplitBillCardSelected
          : classes.PaymentSplitBillCard
      }
      onClick={() => onClicked({ name: "Split Bill" })}
    >
      {isSelected && <Icon name="tick" />}
      <div className={classes.FlexBox}>
        <div className={classes.ContentWrapper}>
          <span className={classes.Percentage}>%</span>
          <span className={classes.Name}>Split Bill</span>
        </div>
      </div>
    </li>
  );
};

export default SplitBillCard; 