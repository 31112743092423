import ErrorImg from "assets/img/errorOne.webp";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import Loader from "components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { AuthContext } from "context/authContext";
import Order from "components/Order/Order";
import PrimaryButton from "components/PrimaryButton/PrimaryButton";
// import classes from "./AllOrdersPage.module.scss";
import { useNavigate } from "react-router-dom";
import useToggle from "hooks/useToggle";
import PrimaryDrawerCart from "components/PrimaryDrawerCart/PrimaryDrawerCart";
import { clearCheckoutChannel } from "redux/channel";
import {
  clearOrder,
  switchToCartEditing,
  // toggleOrderTypes,
} from "redux/createOrder";
import {
  changeActiveCart,
  // changeActiveCart,
  clearCart,
  // toggleOrderTypesInCart,
} from "redux/cart";
import {
  addEditingOrder,
  addSelectedOrder,
  removeSelectedOrders,
} from "redux/editingOrder";
// import { fetchServices } from "redux/service";
import { clearCategoryError } from "redux/category";
import { clearDishError } from "redux/dish";
import HeaderFilter from "./components/HeaderFilter";
import { ORDERTYPES } from "utillity/productionData";
import styled from "styled-components";
// import NoOrderScreen from "./components/NoOrderScreen";
import axiosWithLocationV2 from "config/axiosWithLocationV2";
import useEventListener from "hooks/useEventListener";
import MainLoader from "components/Loader/MainLoader";
import { toast, ToastContainer } from "react-toastify";
import { RemoveScroll } from "react-remove-scroll";
import { clearCheckoutTender } from "redux/tenders";
import DeliverectOrder from "./components/DeliverectOrder";
import DeliverectDrawerCart from "components/deliverectDrawerCart/DeliverectDrawerCart";
import {
  ORDER_CREATE_PERMISSION,
  ORDER_UPDATE_PERMISSION,
} from "constants/constants";
import { usePermissionHooks } from "hooks/usePermissionHooks";
import { useDefaultSetting } from "hooks/useDefaultSetting";
import VerifyPinModal from "components/Modal/VerifyPinModal/VerifyPinModal";
import { useGetActiveLocation } from "hooks/useGetActiveLocation";

const Orders = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token, manualLogout } = useContext(AuthContext);
  const { selectedLocation } = useSelector((state) => state.location);
  const [mainOrders, setMainOrders] = useState([]);
  const [showPinModal, setShowPinModal] = useState(false);
  const [selectedOrderType, setSelectedOrderType] = useState(ORDERTYPES[0]);
  const [showDrawer, toggleDrawer] = useToggle(false);
  const [showDeliverectDrawer, toggleDeliverectDrawer] = useToggle(false);
  const [editingOrder, setEditingOrder] = useState(null);
  const [page, setPage] = useState(1);
  const [paginationDetails, setPaginationDetails] = useState({});
  const [moreDataLoading, setMoreDataLoading] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const {pos_pin_status} = useGetActiveLocation();

  // To determine if the logged in user can create a new order
  const { canView: canCreateOrder } = usePermissionHooks(
    ORDER_CREATE_PERMISSION
  );
  const { canView: canUpdateOrder } = usePermissionHooks(
    ORDER_UPDATE_PERMISSION
  );

  // Check for default setting
  const { default_service_type, chooseOrderServiceType, skip_customer_info } =
    useDefaultSetting();

  // const shiftStatus = localStorage.getItem("shiftStatus");

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop + 1 >=
      document.documentElement.scrollHeight
    ) {
      if (
        paginationDetails &&
        paginationDetails?.next_page &&
        paginationDetails?.current_page <= paginationDetails?.total_pages
      ) {
        setPage(paginationDetails?.next_page);
        setMoreDataLoading(true);
      }
    }
  };
  useEventListener("scroll", handleScroll, window);

  useMemo(() => {
    if (showDrawer) {
      window.scrollTo({ top: 0 });
    }
  }, [showDrawer]);

  // useEffect(() => {
  //   if (!token) {
  //     navigate("/login");
  //     clearCookies();
  //   }
  // }, [token]);

  const onChangeFilter = useCallback((newValue) => {
    setSelectedOrderType(newValue);
  }, []);

  const showOrder = (order) => {
    // console.log(order, "Active Order");
    toggleDrawer();
    dispatch(addEditingOrder(order));
    setEditingOrder(order);
  };

  // Function that is called when the create order button is clicked
  const handlePinVerification = () => {
    if(pos_pin_status){
      setShowPinModal(true);
    }else {
      createNewOrder()
    }
  };

  const onPinVerification = (isPinVerified) => {
    if(isPinVerified) {
      createNewOrder()
    }
  }
  const createNewOrder = () => {  
      if (canCreateOrder) {
        clearAll();
        if (
          !default_service_type ||
          default_service_type === null ||
          default_service_type === ""
        ) {
          navigate("/orders/create/order-type");
        } else {
          chooseOrderServiceType(default_service_type);
          dispatch(changeActiveCart(default_service_type));
          if (default_service_type !== "dine in") {
            navigate("/orders/create/category-selection");
          } else {
            if (skip_customer_info) {
              navigate("/orders/create/table-allocation");
            } else {
              navigate("/orders/create/allocation-steps");
            }
          }
        }
      } else {
        toast.warning("You don not have permission to perform this action", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
  };
  const orderUrl =
    selectedOrderType.value !== "all" && selectedOrderType.value !== "on-hold"
      ? `/orders?page=${page}&status=${selectedOrderType.value}`
      : `/orders?page=${page}`;
  const liveOrderUrl = `/live-orders?page=${page}`;
  // Fetching orders
  const fetchOrders = useCallback(() => {
    if (
      token &&
      selectedLocation !== null &&
      selectedOrderType.value !== "live-orders"
    ) {
      setIsLoading(true);
      setError("");
      axiosWithLocationV2
        .get(orderUrl)
        .then((res) => {
          if (page === 1) {
            setMainOrders([]);
          }
          setMainOrders((prev) => [...prev, ...res?.data?.data?.data]);
          setPaginationDetails(res?.data?.data?.extra);
          setMoreDataLoading(false);
          setIsLoading(false);
        })
        .catch((err) => {
          if (err?.response && err.response?.status === 401) {
            toast.error(
              err?.response?.data?.message ||
                "Session expired.Please login again",
              {
                position: toast.POSITION.BOTTOM_RIGHT,
              }
            );
            manualLogout();
          }
          setMoreDataLoading(false);
          setIsLoading(false);
          setError(
            err?.response?.data?.message ||
              "Error fetching your orders. Please try again later."
          );
        });
    } else if (
      token &&
      selectedLocation !== null &&
      selectedOrderType.value === "live-orders"
    ) {
      setIsLoading(true);
      setError("");
      axiosWithLocationV2
        .get(liveOrderUrl)
        .then((res) => {
          // console.log(res, "Live Orders");
          if (page === 1) {
            setMainOrders([]);
          }
          setMainOrders((prev) => [...prev, ...res?.data?.data?.data]);
          setPaginationDetails(res?.data?.data?.extra);
          setMoreDataLoading(false);
          setIsLoading(false);
        })
        .catch((err) => {
          if (err?.response && err.response?.status === 401) {
            toast.error(
              err?.response?.data?.message ||
                "Session expired.Please login again",
              {
                position: toast.POSITION.BOTTOM_RIGHT,
              }
            );
            manualLogout();
          }
          setMoreDataLoading(false);
          setIsLoading(false);
          setError(
            err?.response?.data?.message ||
              "Error fetching your orders. Please try again later."
          );
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    token,
    selectedLocation,
    page,
    selectedOrderType.value,
    liveOrderUrl,
    manualLogout,
    orderUrl,
  ]);
  // console.log(mainOrders, "mainOrders");

  useEffect(() => {
    fetchOrders();
    dispatch(removeSelectedOrders());
    // window.scrollTo({ top: 0, behavior: "smooth" });
  }, [fetchOrders, dispatch]);

  const clearAll = useCallback(() => {
    dispatch(clearCart());
    dispatch(clearOrder());
    dispatch(clearCategoryError());
    dispatch(clearDishError());
    dispatch(clearCheckoutChannel());
    dispatch(clearCheckoutTender());
    // dispatch(removeSelectedOrders());
  }, [dispatch]);

  /* Clear order and cart if went back to this page from order creation */
  useEffect(() => {
    clearAll();
  }, [clearAll]);

  let allOrders = null;
  // let ordersStyleClasses = classes.NoOrders;
  let emptyFilter = [];

  /**
   * *Note to change the selected type here to draft when you done
   */
  const orderAvailable =
    mainOrders &&
    mainOrders?.length === 0 &&
    selectedOrderType?.value === "live-orders"
      ? false
      : true;
  // const orderAvailable = mainOrders && mainOrders?.length >= 1 ? true : false;

  if (mainOrders && mainOrders?.length > 0) {
    // ordersStyleClasses = classes.AllOrders;
    let isRenderNotAvailableOption = true;
    const uuidArr = [];

    allOrders = mainOrders
      ?.filter((order) => {
        const notAvailableOptionsInAPI = ["reserved"];

        if (notAvailableOptionsInAPI.includes(selectedOrderType.value)) {
          emptyFilter = [];
          return true;
        }

        if (
          selectedOrderType.value === "all" ||
          selectedOrderType.value === "live-orders"
        ) {
          emptyFilter = mainOrders;
          return true;
        } else {
          emptyFilter = mainOrders?.filter(
            (order) => order.status === selectedOrderType.value
          );
          return order.status === selectedOrderType.value;
        }
      })
      .sort(
        (a, b) =>
          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
      )
      .map((order, index) => {
        const notAvailableOptionsInAPI = ["reserved"];
        if (
          notAvailableOptionsInAPI.includes(selectedOrderType.value) &&
          isRenderNotAvailableOption
        ) {
          isRenderNotAvailableOption = false;
          emptyFilter = [];
          return null;
        } else if (
          !notAvailableOptionsInAPI?.includes(selectedOrderType?.value)
        ) {
          if (
            selectedOrderType &&
            selectedOrderType?.value === "live-orders" &&
            order?.platform === "Deliverect"
          ) {
            return (
              <DeliverectOrder
                toggleDrawer={() => toggleDeliverectDrawer()}
                key={index}
                order={order}
              />
            );
          } else if (typeof order.uuid === "object") {
            const multiOrderTypes = [];
            multiOrderTypes?.push({
              type: order.order_type,
              status: order?.status,
              uuid: order?.uuid,
              id: order?.id,
              orderItems: order?.orderItems,
            });
            return (
              <Order
                key={order.created_at + index}
                {...order}
                onClicked={() => {
                  toast.warning("No identifier for this order", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                  });
                  // dispatch(addSelectedOrder(multiOrderTypes));
                  // showOrder(order);
                }}
                multiOrderTypes={multiOrderTypes}
                activeTab={selectedOrderType}
              />
            );
          } else if (!uuidArr.includes(order?.uuid)) {
            uuidArr.push(order.uuid);
            const getSameUuidOrder = mainOrders?.filter(
              (row) => row.uuid === order.uuid
            );
            // console.log("Orders From same Id", getSameUuidOrder);
            const multiOrderTypes = [];
            const storingDetailsForCheckout = [];
            // console.log(storingDetailsForCheckout, "Checkout");
            getSameUuidOrder?.map((x) => {
              storingDetailsForCheckout.push({
                type: x.order_type,
                status: x?.status,
                order_no: x.order_no,
                orderItems: x?.orderItems,
                id: order?.id,
                outlet_table_id: x?.outlet_table_id,
                outletTable: x?.outletTable,
                zone: x?.zone,
                vatvalue: x?.vatvalue,
                order_price: x?.order_price,
                total: x?.total,
                discount_type: x?.discount_type,
                discount_value: x?.discount,
                client_name: x?.client_name,
                created_at: x?.created_at,
                total_discount: x?.total_discount,
                comment: x?.comment,
              });
              multiOrderTypes?.push({
                type: x.order_type,
                status: x?.status,
                uuid: x?.uuid,
                orderItems: x?.orderItems,
                id: order?.id,
                outlet_table_id: x?.outlet_table_id,
                outletTable: x?.outletTable,
                zone: x?.zone,
                vatvalue: x?.vatvalue,
                order_price: x?.order_price,
                total: x?.total,
                discount_type: x?.discount_type,
                discount_value: x?.discount,
                total_discount: x?.total_discount,
                comment: x?.comment,
              });
              return null;
            });
            return (
              <Order
                key={order.created_at + index}
                {...order}
                multiOrderTypes={storingDetailsForCheckout}
                onClicked={(arr) => {
                  dispatch(addSelectedOrder(arr));
                  showOrder(order);
                }}
                activeTab={selectedOrderType}
              />
            );
          }
          return null;
        }
        return null;
      });
  }

  const onProceedCart = () => {
    navigate("/orders/checkout/payment-channel");
  };

  /* Adding dummy data as there is no order data in the API */
  const onEditCart = async () => {
    if (!canUpdateOrder) {
      toast.warning("You don not have permission to perform this action", {
        position: toast.POSITION.BOTTOM_RIGHT,
      });
      return;
    }

    dispatch(switchToCartEditing(true));
    navigate("/orders/create/dish-selection");
  };

  if (!error && isLoading && !moreDataLoading) {
    return (
      <div className="h-screen">
        <Loader />
      </div>
    );
  } else if (error && !isLoading) {
    return (
      <div className="w-full h-screen  flex flex-col items-center justify-center">
        <img
          src={ErrorImg}
          alt="Error Illustration"
          className="block w-[200px] h-[200px]"
        />
        <p className="text-3xl lg:text-4xl font-Inter font-medium text-red-300">
          {error}
        </p>
        <div className="mt-16 w-full flex flex-row items-center justify-center">
          <PrimaryButton
            text="Try Again"
            onClicked={() => {
              fetchOrders();
              // dispatch(fetchAllOrders(page));
            }}
          />
        </div>
      </div>
    );
  } else if ((!error && orderAvailable) || (!orderAvailable && !isLoading)) {
    return (
      <div className="w-full min-h-screen">
        {showDrawer && (
          <RemoveScroll>
            <PrimaryDrawerCart
              onProceed={onProceedCart}
              onEdit={onEditCart}
              toggleDrawer={toggleDrawer}
              order={editingOrder}
              clearAll={clearAll}
            />
          </RemoveScroll>
        )}
        {showDeliverectDrawer && (
          <RemoveScroll>
            <DeliverectDrawerCart
              toggleDrawer={() => toggleDeliverectDrawer()}
            />
          </RemoveScroll>
        )}

        <VerifyPinModal
          show={showPinModal}
          setShowPinModal={setShowPinModal}
          onPinVerification={onPinVerification}
        />
        <div
        // className={classes.AllOrdersPage}
        >
          {/* Create Order button */}
          {canCreateOrder && (
            <div className="w-full lg:flex flex-row justify-end mb-14 hidden">
              <PrimaryButton
                text="New Order طلب جديد"
                onClicked={handlePinVerification}
              />
            </div>
          )}
          <HeaderFilter
            selectedOption={selectedOrderType}
            onChangeFilter={(val) => {
              setPage(1);
              onChangeFilter(val);
            }}
            createNewOrder={createNewOrder}
            data={ORDERTYPES}
          />
          <ContainerDiv className="w-full  grid md:grid-cols-2 xl:grid-cols-3 gap-5 mt-20">
            {allOrders}

            {emptyFilter.length === 0 && (
              <div className="w-[80vw] lg:w-[70vw] h-[70vh] flex flex-row mt-20 justify-center">
                <p className="text-3xl font-Inter font-medium text-greyText text-center">
                  No Record found
                </p>
              </div>
            )}
          </ContainerDiv>
          {moreDataLoading && (
            <div className="w-full flex flex-row items-center justify-center mt-5">
              <MainLoader />
            </div>
          )}
        </div>

        <ToastContainer />
      </div>
    );
  }
  // else if (!error && !isLoading && !orderAvailable) {
  //   return (
  //     <div className="w-full min-h-screen">
  //       <ContainerDiv className="w-full  grid md:grid-cols-2 xl:grid-cols-3 gap-5 mt-20">
  //         {/* <NoOrderScreen navigate={() => navigate("/orders/create/order-type")} /> */}
  //         {allOrders}
  //       </ContainerDiv>
  //     </div>
  //   );
  // }
  else {
    return <div className="w-full h-full "></div>;
  }
};

export default Orders;

const ContainerDiv = styled.div`
  @media (min-width: 1580px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  @media (min-width: 2000px) {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
`;
