import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from './SplitBillScreen.module.scss';
import { useNavigate } from 'react-router-dom';
import { fetchAllTenders } from 'redux/tenders';
import { useDispatch } from 'react-redux';
import { AuthContext } from "context/authContext";
import { useContext } from 'react';
import axios from 'axios';
import { useGetActiveLocationWithOrderTab } from "hooks/useTaxHooks";
import { useGetActiveLocation } from "hooks/useGetActiveLocation";
// Define or import TENDER_OPTIONS
// const TENDER_OPTIONS = [
//   { id: 'cash', name: 'Cash', icon: '💵' },
//   { id: 'mastercard', name: 'Mastercard', icon: '💳' },
//   { id: 'mada', name: 'Mada', icon: '💳' },
//   { id: 'visa', name: 'Visa', icon: '💳' }
// ];
const SplitBillScreen = () => {
  const dispatch = useDispatch();
  const {currentLocation} = useGetActiveLocation();

  // Access the order from the Redux state
  const order = useSelector((state) => state.editOrder.order);
  const { token } = useContext(AuthContext);
  const { checkoutChannel } = useSelector((state) => state.channel);

  const { currency } = useGetActiveLocationWithOrderTab({checkoutChannel});
  // Use orderId and totalAmount from the Redux state
  const orderId = `#${order.order_no}`;
  const totalAmount = order?.total || 75; // Default to 75 if total is not available

  const [splitCount, setSplitCount] = useState(2);
  const [activeTab, setActiveTab] = useState('Equal Split');
  const [showTenderModal, setShowTenderModal] = useState(false);
  const [selectedGuestId, setSelectedGuestId] = useState(null);
  const [guestTenders, setGuestTenders] = useState({});
  const [receivedAmount, setReceivedAmount] = useState(0);
  const [guestStatus, setGuestStatus] = useState({});
  const [customAmounts, setCustomAmounts] = useState({});
const [TENDER_OPTIONS,setTenderOptions] = useState([]);
const [totalTaxAmount, setTotalTaxAmount] = useState(totalAmount);
  const navigate = useNavigate();
// Getting the channel when order type does contain is dine in
useEffect(() => {


  const orderId = `${order?.id}`;



  // Check if orderId is available
  if (orderId) {
    const getTenders = async () => {
      const res = await dispatch(fetchAllTenders({ order_id: orderId }));
      setTenderOptions(res?.payload?.data?.data);
    };
    getTenders();
  } else {

  }
}, [order, dispatch]);

  useEffect(() => {
    const remainingAmount = totalAmount - receivedAmount;
    if (remainingAmount < 0) {
      toast.error('The total received amount exceeds the grand total!');
    }
  }, [receivedAmount, totalAmount]);

  // Add this helper function to calculate amount with tax
  const calculateAmountWithTax = (amount, tender) => {
    if (!tender) return amount;
    
    let taxAmount = 0;
    if (tender.tax_type?.toLowerCase() === 'fixed') {
      taxAmount = tender.tax_value;
    } else if (tender.tax_type?.toLowerCase() === 'percentage') {
      taxAmount = (amount * tender.tax_value) / 100;
    }
    return amount + taxAmount;
  };

  // Update the guests mapping to include tax in displayed amounts
  const guests = Array.from({ length: splitCount }, (_, index) => {
    const baseAmount = activeTab === 'Equal Split' ? totalAmount / splitCount : customAmounts[index + 1] || 0;
    const tender = guestTenders[index + 1];
    const amountWithTax = calculateAmountWithTax(baseAmount, tender);
    
    return {
      id: index + 1,
      amount: baseAmount,
      amountWithTax: amountWithTax,
      status: guestStatus[index + 1] || false
    };
  });

  const handleTenderSelect = (tenderId) => {
    const selectedTender = TENDER_OPTIONS.find(t => t.id === tenderId);
    setGuestTenders(prev => ({
      ...prev,
      [selectedGuestId]: {
        id: selectedTender.id,
        name: selectedTender.name,
        tax_value: selectedTender.tax,
        tax_type: selectedTender.tax_type,
        image: selectedTender.image,
        order_tender_tax: selectedTender.order_tender_tax
      }
    }));
    setShowTenderModal(false);
  };

  const openTenderModal = (guestId) => {
    setSelectedGuestId(guestId);
    setShowTenderModal(true);
  };

  // Update handleCheckboxChange to use amountWithTax
  const handleCheckboxChange = (guestId) => {
    if (!guestTenders[guestId]) {
      toast.error('Please select a tender method before confirming.');
      return;
    }

    setGuestStatus(prev => {
      const newStatus = { ...prev, [guestId]: !prev[guestId] };
      
      // Calculate new received amount including tax
      const newReceivedAmount = guests.reduce((sum, guest) => {
        if (!newStatus[guest.id]) return sum;
        return sum + guest.amountWithTax;
      }, 0);

      if (activeTab === 'Equal Split') {
        setEqualSplitReceived(newReceivedAmount);
      } else {
        setCustomSplitReceived(newReceivedAmount);
      }
      
      return newStatus;
    });
  };

  const handleCustomAmountChange = (guestId, value) => {
    const numValue = parseFloat(value) || 0;
    setCustomAmounts(prev => ({
      ...prev,
      [guestId]: numValue
    }));

    // Recalculate received amount for custom split
    const newReceivedAmount = guests.reduce((sum, guest) => {
      if (!guestStatus[guest.id]) return sum;
      const guestAmount = guest.id === guestId ? numValue : (customAmounts[guest.id] || 0);
      const tender = guestTenders[guest.id];
      return sum + calculateAmountWithTax(guestAmount, tender);
    }, 0);
    
    setCustomSplitReceived(newReceivedAmount);
  };

  const areAllGuestsConfirmed = () => {
    return guests.every(guest => {
      // Check if guest is confirmed and has tender selected
      const basicChecks = guestStatus[guest.id] && guestTenders[guest.id];
      
      if (activeTab === 'Custom Split') {
        // For custom split, ensure amount is entered and greater than 0
        const hasValidAmount = customAmounts[guest.id] && customAmounts[guest.id] > 0;
        return basicChecks && hasValidAmount;
      }
      
      return basicChecks;
    });
  };

  const isCustomSplitValid = () => {
    if (activeTab !== 'Custom Split') return true;
    
    const totalCustomAmount = guests.reduce((sum, guest) => {
      return sum + (customAmounts[guest.id] || 0);
    }, 0);
    
    // Allow for small floating-point differences (e.g., 0.01)
    return Math.abs(totalCustomAmount - totalAmount) < 0.01;
  };

  const handleOrderSave = async () => {
    if (!areAllGuestsConfirmed()) {
      if (activeTab === 'Custom Split') {
        toast.error('Please ensure all guests have valid amounts, selected tenders, and are confirmed.');
      } else {
        toast.error('Please confirm all guest payments before proceeding.');
      }
      return;
    }

    if (!isCustomSplitValid()) {
      toast.error('The sum of custom split amounts must equal the total bill amount.');
      return;
    }

    const payload = {
      order_id: order.id,
      grand_total: totalTaxAmount,
      splits: guests.map(guest => {
        const selectedTender = guestTenders[guest.id];
        const splitAmount = guest.amount;
        
        // Calculate tax amount based on tender's tax type and value
        let taxApplied = 0;
        if (selectedTender) {
          if (selectedTender.tax_type?.toLowerCase() === 'fixed') {
            taxApplied = selectedTender.tax_value;
          } else if (selectedTender.tax_type?.toLowerCase() === 'percentage') {
            taxApplied = (splitAmount * selectedTender.tax_value) / 100;
          }
        }

        // Calculate final amount including tax
        const finalAmount = splitAmount + taxApplied;

        // Match the exact format expected by the backend
        let normalizedTaxType = 'Fixed';  // Default to "Fixed"
        if (selectedTender?.tax_type) {
          // Capitalize first letter, lowercase the rest
          normalizedTaxType = selectedTender.tax_type.charAt(0).toUpperCase() + 
                             selectedTender.tax_type.slice(1).toLowerCase();
        }

        return {
          guest_number: guest.id,
          split_amount: splitAmount,
          tender_id: selectedTender?.id || null,
          tender_tax_value: selectedTender?.tax_value || 0,
          tender_tax_type: normalizedTaxType,
          tender_tax_applied: taxApplied,
          final_amount: finalAmount,
          tender_name: selectedTender?.name || null,
          payment_status: guestStatus[guest.id] ? "Received" : "Pending"
        };
      })
    };

    try {
      const response = await axios.post('/order-split-details', payload, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Location': currentLocation.id
        }
      });
      console.log(<response className="data stau"></response>, "response.data");

      if (response.data.status === true) {
        // toast.success('Order split details saved successfully');
        navigate("/orders/checkout/receipt");
      } else {
        // toast.error(response.data.message || 'Failed to save order split details.');
      }
    } catch (error) {
      console.error('Error saving order split details:', error);
      toast.error('An error occurred while saving order split details.');
    }
  };

  // Add new state variables for separate tracking
  const [equalSplitReceived, setEqualSplitReceived] = useState(0);
  const [customSplitReceived, setCustomSplitReceived] = useState(0);

  // Add effect to reset guest status and received amount when switching tabs
  useEffect(() => {
    setGuestStatus({});
    setGuestTenders({});
    if (activeTab === 'Equal Split') {
      setCustomAmounts({});
    }
  }, [activeTab]);

  // Add this effect to update totalTaxAmount whenever guest tenders change
  useEffect(() => {
    let totalWithTax = totalAmount;
    
    guests.forEach(guest => {
      const selectedTender = guestTenders[guest.id];
      if (selectedTender) {
        let taxApplied = 0;
        if (selectedTender.tax_type?.toLowerCase() === 'fixed') {
          taxApplied = selectedTender.tax_value;
        } else if (selectedTender.tax_type?.toLowerCase() === 'percentage') {
          taxApplied = (guest.amount * selectedTender.tax_value) / 100;
        }
        totalWithTax += taxApplied;
      }
    });
    
    setTotalTaxAmount(totalWithTax);
  }, [guestTenders, guests, totalAmount]);

  // Update the summaryCards section to show total with tax
  const summaryCards = (
    <div className={styles.summaryCards}>
      <div className={styles.card}>
        <span>Received</span>
        <h3>{currency} {activeTab === 'Equal Split' ? equalSplitReceived : customSplitReceived}</h3>
      </div>
      <div className={styles.card}>
        <span>Remaining</span>
        <h3>{currency} {totalTaxAmount - (activeTab === 'Equal Split' ? equalSplitReceived : customSplitReceived)}</h3>
      </div>
      <div className={styles.card}>
        <span>Grand Total</span>
        <h3>{currency} {totalTaxAmount}</h3>
      </div>
    </div>
  );

  // Add helper function to calculate tax amount
  const calculateTaxAmount = (amount, tender) => {
    if (!tender) return 0;
    
    let taxAmount = 0;
    if (tender.tax_type?.toLowerCase() === 'fixed') {
      taxAmount = tender.tax_value;
    } else if (tender.tax_type?.toLowerCase() === 'percentage') {
      taxAmount = (amount * tender.tax_value) / 100;
    }
    return taxAmount;
  };

  return (
    <div className={styles.splitBillContainer}>
      <ToastContainer position="top-right" autoClose={3000} />
      <div className={styles.headerSection}>
        <div className={styles.titleWrapper}>
          <div className={styles.titleSection}>
            <h1>Split Bill</h1>
            <span className={styles.dineInTag}>Dine in</span>
          </div>
          <span className={styles.orderIdText}>Order ID {orderId}</span>
        </div>
      </div>

      <div className={styles.tabContainer}>
        <button 
          className={`${styles.tabButton} ${activeTab === 'Equal Split' ? styles.active : ''}`}
          onClick={() => setActiveTab('Equal Split')}
        >
          Equal Split
        </button>
        <button 
          className={`${styles.tabButton} ${activeTab === 'Custom Split' ? styles.active : ''}`}
          onClick={() => setActiveTab('Custom Split')}
        >
          Custom Split
        </button>
      </div>

      {summaryCards}

      <div className={styles.splitCounterContainer}>
        <div className={styles.splitCounter}>
          <button onClick={() => setSplitCount(prev => Math.max(2, prev - 1))}>−</button>
          <div className={styles.inputContainer}>
            <input 
              type="text" 
              value={splitCount}
              onChange={(e) => setSplitCount(Math.max(2, parseInt(e.target.value) || 2))}
            />
          </div>
          <button onClick={() => setSplitCount(prev => prev + 1)}>+</button>
        </div>
        <p className={styles.splitHint}>This indicates the number of splits for each equal split</p>
      </div>

      <div className={styles.tableContainer}>
        <table className={styles.guestsTable}>
          <thead>
            <tr>
              <th>Guests</th>
              <th>Tenders</th>
              <th>{activeTab === 'Equal Split' ? 'Equal Split' : 'Custom Split'}</th>
              <th>Tax Amount</th>
              <th>Total with Tax</th>
              <th>Confirm Status</th>
            </tr>
          </thead>
          <tbody>
            {guests.map((guest) => {
              const baseAmount = activeTab === 'Equal Split' ? totalAmount / splitCount : customAmounts[guest.id] || 0;
              const tender = guestTenders[guest.id];
              const taxAmount = calculateTaxAmount(baseAmount, tender);
              const totalWithTax = baseAmount + taxAmount;

              return (
                <tr key={guest.id}>
                  <td>Guest {guest.id}</td>
                  <td>
                    <button 
                      className={`${styles.tenderButton} ${tender ? styles.selectedTender : ''}`}
                      onClick={() => openTenderModal(guest.id)}
                    >
                      {tender ? tender.name : 'Select Tender'}
                      <span>→</span>
                    </button>
                  </td>
                  <td>
                    {activeTab === 'Equal Split' ? (
                      `${currency} ${baseAmount.toFixed(2)}`
                    ) : (
                      <div className={styles.inputContainer}>
                        <input
                          type="number"
                          className={`${styles.customAmountInput} ${
                            customAmounts[guest.id] > totalAmount ? styles.hasError : ''
                          }`}
                          value={customAmounts[guest.id] || ''}
                          onChange={(e) => handleCustomAmountChange(guest.id, e.target.value)}
                          placeholder="0.00"
                          min="0"
                          step="0.01"
                        />
                      </div>
                    )}
                  </td>
                  <td>{currency} {taxAmount.toFixed(2)}</td>
                  <td>{currency} {totalWithTax.toFixed(2)}</td>
                  <td>
                    <input 
                      type="checkbox" 
                      className={styles.statusCheckbox} 
                      checked={guest.status}
                      onChange={() => handleCheckboxChange(guest.id)}
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {showTenderModal && (
        <div className={styles.modal}>
          <div className={styles.modalContent}>
            <h3>Select Tender List</h3>
            <div className={styles.tenderGrid}>
              {TENDER_OPTIONS.map((tender) => {
                // Calculate tax for display
                const baseAmount = activeTab === 'Equal Split' 
                  ? totalAmount / splitCount 
                  : customAmounts[selectedGuestId] || 0;
                
                let taxAmount = 0;
                if (tender.tax_type?.toLowerCase() === 'fixed') {
                  taxAmount = tender.tax;
                } else if (tender.tax_type?.toLowerCase() === 'percentage') {
                  taxAmount = (baseAmount * tender.tax) / 100;
                }

                return (
                  <button
                    key={tender.id}
                    className={styles.tenderOption}
                    onClick={() => handleTenderSelect(tender.id)}
                  >
                    <img className={styles.tenderIcon} src={tender.image} alt={tender.name} />
                    <div className={styles.tenderDetails}>
                      <span>{tender.translations?.en?.name || tender.name}</span>
                      <span className={styles.taxInfo}>
                        {taxAmount > 0 && ` :Tax: (${currency} ${taxAmount.toFixed(2)})`}
                      </span>
                    </div>
                  </button>
                );
              })}
            </div>
            <div className={styles.modalActions}>
              <button 
                className={styles.cancelBtn}
                onClick={() => setShowTenderModal(false)}
              >
                Cancel
              </button>
              <button className={styles.confirmBtnModal}>Confirm</button>
            </div>
          </div>
        </div>
      )}

      <div className={styles.actions}>
        <button className={styles.cancelBtn} onClick={() => navigate("/orders/all")}>
          Cancel
        </button>
        <button 
          className={`${styles.confirmBtn} ${!areAllGuestsConfirmed() || !isCustomSplitValid() ? styles.disabled : ''}`}
          onClick={handleOrderSave}
          disabled={!areAllGuestsConfirmed() || !isCustomSplitValid()}
        >
          Confirm
        </button>
      </div>
    </div>
  );
};

export default SplitBillScreen; 