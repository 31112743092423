import React, { useEffect, useRef, useState } from "react";
import Modal from "../Modal";
import Icon from "components/Icon/Icon";
import classes from "./VerifyPinModal.module.scss";
import { ResturantService } from "services/ResturantService";
import { useDispatch } from "react-redux";
import { setVerificationPin } from "redux/editingOrder";

const VerifyPinModal = ({ show, setShowPinModal, onPinVerification }) => {
  const [pin, setPin] = useState(["", "", "", ""]);
  const [error, setError] = useState("");
  const inputsRef = useRef([]);
  const dispatch = useDispatch();

  const handleInputChange = (value, index) => {
    if (!/^\d?$/.test(value)) return; // Allow only one digit (0-9)

    const newPin = [...pin];
    newPin[index] = value;
    setPin(newPin);

    if (value && index < pin.length - 1) {
      inputsRef.current[index + 1]?.focus();
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === "Backspace" && !pin[index] && index > 0) {
      setError("");
      inputsRef.current[index - 1]?.focus();
    }
  };

  const handlePaste = (event) => {
    const pasteData = event.clipboardData.getData("Text").slice(0, 4); // Take only the first 4 digits
    if (/^\d{1,4}$/.test(pasteData)) {
      const newPin = pasteData.split("");
      setPin([...newPin, "", "", "", ""].slice(0, 4)); // Fill the inputs and pad to 4 digits
      newPin.forEach((digit, idx) => {
        if (inputsRef.current[idx]) {
          inputsRef.current[idx].value = digit;
        }
      });
      event.preventDefault();
    }
  };

  const handleVerifyPin = (e) => {
    e.preventDefault();
    ResturantService.verifyPin(
      { pos_pin: parseInt(pin.join("")) },
      (res) => {
        if (res.data.status) {
          onPinVerification(true);
          dispatch(setVerificationPin(parseInt(pin.join(""))));
        }
      },
      (err) => {
        console.log(err?.response?.data?.message);
        setError(err?.response?.data?.message);
        onPinVerification(false);
      }
    );
  };

  useEffect(() => {
    if (show && inputsRef.current[0]) {
      setPin(["", "", "", ""])
      inputsRef.current[0].focus();
      setError("");
    }
  }, [show]);
  return (
    <Modal show={show} onClickOutside={() => setShowPinModal(false)}>
      <div className={classes.Form}>
        <div className={classes.ModalHeader}>
          <Icon name="key-icon" height={3.5} width={3.5} />
          <strong>Enter PIN</strong>
          <p>Please enter the PIN to access the POS.</p>
        </div>
        <>
          <form className={classes.Base}>
            <div style={{ display: "flex", gap: "10px" }}>
              {pin.map((digit, index) => (
                <input
                  key={index}
                  type="text"
                  value={digit ? "*" : ""}
                  maxLength={1}
                  ref={(el) => (inputsRef.current[index] = el)}
                  onChange={(e) => handleInputChange(e.target.value, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  onPaste={handlePaste}
                  className={`${classes.PinInput} ${
                    digit ? classes.ActiveInput : ""
                  }
                  ${error ? classes.ErrorInput : ""}`}
                />
              ))}
            </div>
            {error && <span className="text-red-500">Error: {error}</span>}

            <button
              tabIndex="0"
              onClick={handleVerifyPin}
              className="flex-row w-full items-center justify-center py-4 bg-[#F3781F] rounded cursor-pointer mt-8"
            >
              <span className="text-white text-2xl text-center">Continue</span>
            </button>
          </form>
        </>
      </div>
    </Modal>
  );
};

export default VerifyPinModal;
