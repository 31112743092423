import Icon from "components/Icon/Icon";
import { useContext, useRef, useState, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import classes from "./ReceiptPage.module.scss";
import IconButton from "components/IconButton/IconButton";
import { useSelector } from "react-redux";
import { AuthContext } from "context/authContext";
import LoadingScreen from "components/LoadingScreen/LoadingScreen";
import moment from "../../../../../node_modules/moment/moment";
import axios from "../../../../../node_modules/axios/index";
import {
  toast,
  ToastContainer,
} from "../../../../../node_modules/react-toastify/dist/index";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { switchToCartEditing } from "redux/createOrder";
import useToggle from "hooks/useToggle";
import PrimaryDrawerCart from "components/PrimaryDrawerCart/PrimaryDrawerCart";
import { addEditingOrder } from "redux/editingOrder";
import { useTranslation } from "react-i18next";
import Payment from "components/Cart/Payment/Payment";
import { changeActiveCart } from "redux/cart";
// import { ResturantService } from "services/ResturantService";
import { clearCheckoutChannel } from "redux/channel";
import CancelOrderModal from "components/cancelOrderModal/CancelOrderModal";
import SingleOrderType from "components/Cart/SingleOrderType";
import SingleListItem from "components/PrimaryDrawerCart/SingleListItem";
import { Fragment } from "react";
import { useGetActiveLocationWithOrderTab } from "hooks/useTaxHooks";
import { fetchServices } from "redux/service";
import { useCartHooks } from "hooks/useCartHooks";
import { useGetActiveLocation } from "hooks/useGetActiveLocation";
import CheckoutReceiptChangeModal from "components/orderChangeCalcModal/CheckoutReceiptChangeModal";
import MainReceipt from "components/receipt/MainReceipt";
import { useGetTenderTaxHook } from "hooks/useGetTenderTaxHook";
import { OrderServices } from "services/OrderServices";
import { usePermissionHooks } from "hooks/usePermissionHooks";
import {
  ORDER_CANCEL_PERMISSION,
  ORDER_UPDATE_PERMISSION,
} from "constants/constants";
import SplitReceipt from "components/receipt/SplitReceipt";

const ReceiptPage = () => {
  // const [receiptLang, setReceiptLang] = useState("en");
  const cartState = useSelector((state) => state.cart);
  const { order, pos_pin } = useSelector((state) => state.editOrder);
  const { checkoutChannel } = useSelector((state) => state.channel);
  const [orderCancelReason, setOrderCancelReason] = useState("");
  const [orderSplits, setOrderSplits] = useState([]);
  const [errorReason, setErrorReason] = useState({
    error: false,
    errorText: "",
  });
  const [orderChangeModal, toggleOrderChangeModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { token, manualLogout } = useContext(AuthContext);
  // const [smallRestaurantLogo, setSmallRestaurantLogo] = useState(null);
  const [showCancelModal, toggleCancelModal] = useToggle(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showDrawer, toggleDrawer] = useToggle(false);
  const [editingOrder, setEditingOrder] = useState(null);
  // const [restaurant, setRestaurant] = useState({});
  const { t } = useTranslation();
  const receiptRef = useRef();
  const { translations: servicesTranslations } = useSelector(
    (state) => state.service
  );
  const { checkoutTender } = useSelector((state) => state.tender);
  // const [orderTypes, setOrderTypes] = useState([]);
  const { selectedOrders, checkoutOrder } = useSelector(
    (state) => state.editOrder
  );

  const [activeTab, setActiveTab] = useState({});
  const [activeOrder, setActiveOrder] = useState(checkoutOrder?.type);
  const {
    discountPriceReceipt,
    showDiscountReceipt,
    discountTypeReceipt,
    // discountValue,
    vatReceipt,
    bohDiscount,
  } = useCartHooks();
  const [amount, setAmount] = useState(0);
  const [isPaid, setIsPaid] = useState(false);
  const [qrCode, setQrCode] = useState("");

  const { tax, taxEnabled, channelCommission, showChannel } =
    useGetActiveLocationWithOrderTab({
      activeTab,
      checkoutChannel,
    });
  const { restaurantId, locationId, decimal_preference, currentLocation } =
    useGetActiveLocation();

  const { canView: canCancelOrder } = usePermissionHooks(
    ORDER_CANCEL_PERMISSION
  );
  const { canView: canUpdateOrder } = usePermissionHooks(
    ORDER_UPDATE_PERMISSION
  );
  // call the split bill api
  const fetchOrderSplits = async () => {
    try {
      if (!order?.id) return;

      const response = await axios.get(`/order-splits/${order.id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      setOrderSplits(response.data.data.splits);
    } catch (error) {
      if (error.response?.status === 404 && order?.id) {
        console.error("Order splits not found");
      } else if (error.response?.status === 401) {
        console.error("Unauthorized access");
      }
      console.error("Error fetching order splits:", error.message);
    }
  };
  useEffect(() => {
    if (order?.id) {
      fetchOrderSplits();
    }
  }, [order?.id]);
  // Check if any of the order is not completed
  // const isDraft = selectedOrders?.filter((el) => el?.status === "draft");
  // const mainTax = activeTab?.type !== 'dine in' ? tax : 0
  // const showTax = activeTab?.type !== "dine in";

  // Setting it so there is always active service
  useEffect(() => {
    setActiveOrder(checkoutOrder?.type);
    dispatch(changeActiveCart(checkoutOrder?.type));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const splitBillFinalAmount =
    orderSplits?.reduce((acc, curr) => {
      const amount = curr?.final_amount ? Number(curr.final_amount) : 0;
      return acc + amount;
    }, 0) || 0;

  // const handleCompleteAllOrder = () => {
  //   setIsLoading(true);
  //   axios.all(
  //     isDraft.map((item) => {
  //       axios
  //         .post(
  //           "/order/status",
  //           {
  //             restaurant_id: restaurantId,
  //             location_id: locationId,
  //             status: "complete",
  //             order_no: item?.order_no,
  //           },
  //           {
  //             headers: {
  //               Authorization: `Bearer ${token}`,
  //               Location: locationId,
  //             },
  //           }
  //         )
  //         .then((res) => {
  //           setIsLoading(false);
  //           toast.success(res?.data?.message, {
  //             position: toast.POSITION.BOTTOM_RIGHT,
  //           });
  //           setTimeout(() => {
  //             navigate("/orders");
  //             dispatch(clearCheckoutChannel());
  //           }, 1500);
  //         })
  //         .catch((err) => {
  //           setIsLoading(false);
  //           toast.error(
  //             err.response.data.message ||
  //               "An error occurred while completing your orders!",
  //             {
  //               position: toast.POSITION.BOTTOM_RIGHT,
  //             }
  //           );
  //         });
  //     })
  //   );
  // };

  /**
   * @function To get the active tab total price
   * @param It takes the active order tab
   */
  const getActiveTabTotalPrice = (val) => {
    let price = 0;
    const currentTab = Object.keys(cartState?.cart);
    currentTab.forEach((el) => {
      const currentOrderArray = cartState?.cart[val];
      // If the length of currentOrderArray is greater than 1
      if (currentOrderArray?.length >= 1) {
        if (val === el) {
          for (const cartItem of cartState?.cart[val] || []) {
            const item_price =
              +(cartItem?.price + cartItem?.vat) * cartItem?.quantity;

            // Calculate the discounted value for the current item
            const discountedValue =
              cartItem.discount_type === "percentage"
                ? item_price -
                  (item_price * Number(cartItem.discount_value ?? 0)) / 100
                : item_price - Number(cartItem.discount_value ?? 0);
            // Add the discounted value to the total price
            price += discountedValue;
          }
        }
      } else {
        price = 0;
      }
    });

    const activeOrderDiscount = selectedOrders.find(
      (orderItem) => orderItem.type === val
    );
    const priceAfterDiscount =
      Number(activeOrderDiscount?.discount_value) > 0
        ? activeOrderDiscount?.discount_type === "percentage"
          ? price - (price * Number(activeOrderDiscount?.discount_value)) / 100
          : price - Number(activeOrderDiscount?.discount_value)
        : price;

    return priceAfterDiscount.toFixed(3);
  };

  // useLayoutEffect(() => {
  //   ResturantService.getResturantInfo(
  //     (res) => {
  //       setRestaurant(res?.data?.data?.data || {});
  //       setSmallRestaurantLogo(res?.data?.data?.data?.smallimage);
  //     },
  //     (err) => {
  //       console.log({ err });
  //     }
  //   );
  // }, []);

  useEffect(() => {
    dispatch(fetchServices());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onEditCart = async () => {
    dispatch(switchToCartEditing(true));
    navigate("/orders/create/dish-selection");
  };
  const onProceedCart = () => {
    navigate("/orders/checkout/payment-channel");
  };
  const showOrder = () => {
    toggleDrawer();
    dispatch(addEditingOrder(order));
    setEditingOrder(order);
  };
  const clearAll = () => {};

  const getSubTotalPrice = () => {
    let subTotalPrice = 0;
    if (
      cartState &&
      cartState?.cart &&
      cartState?.cart[cartState?.activeCart]
    ) {
      for (const item of cartState?.cart[cartState?.activeCart]) {
        subTotalPrice += Number(item?.price) * Number(item?.quantity);
      }
    }
    // console.log(subTotalPrice);
    return Number(subTotalPrice);
    // return numberFormat(subTotalPrice, decimal_preference);
  };

  const { showTenderTax, tenderFee } = useGetTenderTaxHook({
    tax: checkoutTender?.tax,
    tax_type: checkoutTender?.tax_type,
    tax_value: checkoutTender?.tender_tax,
    order_total: getSubTotalPrice(),
  });

  const getChannelFee = () => {
    if (checkoutChannel?.commission_type === "percentage") {
      return (
        Number(checkoutChannel?.channel_tax) ||
        (Number(channelCommission) * Number(getSubTotalPrice())) / 100
      );
    } else {
      return Number(checkoutChannel?.channel_tax) || Number(channelCommission);
    }
  };

  // Get channel commission if available
  const getChannelPrice = () => {
    let channelFee = 0;
    if (checkoutChannel && checkoutChannel?.commission) {
      // const orderPrice = a;
      // const priceWithTax = getTotalTax() + getSubTotalPrice();
      // const fee =
      //   (Number(checkoutChannel?.commission) / Number(order?.total)) * 100;
      const fee = getChannelFee();
      // const fee =
      //   (Number(channelCommission) *
      //     (getSubTotalPrice() + Number(vatReceipt))) /
      //   100;
      channelFee = fee;
    }
    return Number(channelFee);
    // return numberFormat(channelFee, decimal_preference);
  };

  // console.log(
  //   discountPriceReceipt().toFixed(decimal_preference),
  //   "Discount",
  //   getSubTotalPrice()
  // );

  // Add this helper function to calculate total taxes
  const getTotalTaxAmount = () => {
    if (!taxEnabled || !currentLocation?.taxes) return 0;
    
    return currentLocation.taxes.reduce((total, tax) => {
      const taxAmount = (getSubTotalPrice() * tax.tax_value) / 100;
      return total + Number(taxAmount.toFixed(decimal_preference));
    }, 0);
  };

  // Update the getTotalPrice function to round off the final amount
  const getTotalPrice = () => {
    let totalPrice = 0;
    const subTotal = getSubTotalPrice();
    const taxesTotal = getTotalTaxAmount(); // Get all taxes
    
    if (showChannel && showDiscountReceipt) {
      totalPrice = subTotal + taxesTotal;
      totalPrice += getChannelPrice();
      totalPrice += tenderFee;
      totalPrice -= discountPriceReceipt();
    } else if (!showChannel && showDiscountReceipt) {
      totalPrice = subTotal + taxesTotal;
      totalPrice += tenderFee;
      totalPrice -= discountPriceReceipt();
    } else if (showChannel && !showDiscountReceipt) {
      totalPrice = subTotal + taxesTotal;
      totalPrice += getChannelPrice();
      totalPrice += tenderFee;
    } else if (!showChannel && !showDiscountReceipt) {
      totalPrice = subTotal + taxesTotal;
      totalPrice += tenderFee;
    } else {
      totalPrice = Number(activeTab?.order_price) + taxesTotal;
      totalPrice += tenderFee;
    }

    // Round to nearest integer
    return Math.round(totalPrice);
  };

  const radioChangeHandler = (newValue) => {
    dispatch(changeActiveCart(newValue));
  };

  // Return change when customer is paying with cash
  const return_change = amount - getTotalPrice();

  // Main cancel order function
  const handleCancelOrder = () => {
    setErrorReason({
      ...errorReason,
      error: false,
      errorText: "",
    });
    if (!orderCancelReason) {
      setErrorReason({
        ...errorReason,
        error: true,
        errorText: "Please provide reason for cancelling",
      });
    } else {
      toggleCancelModal();
      setIsLoading(true);
      const body = {
        restaurant_id: restaurantId,
        location_id: locationId,
        status: "cancel",
        order_no: cartState?.checkoutOrder?.order_no,
        reason: orderCancelReason,
        receipt_amount: getTotalPrice(),
        paid_amount: Number(amount),
        return_change: Number(
          Number(return_change).toFixed(decimal_preference)
        ),
        pos_pin,
      };
      axios
        .post("/order/status", body, {
          headers: {
            Authorization: `Bearer ${token}`,
            Location: locationId,
          },
        })
        .then((res) => {
          setOrderCancelReason("");
          setIsLoading(false);
          toast.success(res.data.message || "Order has been canceled.", {
            position: toast.POSITION.BOTTOM_RIGHT,
          });
          setTimeout(() => {
            navigate("/orders");
            dispatch(clearCheckoutChannel());
          }, 1000);
        })
        .catch((err) => {
          setIsLoading(false);
          if (err && err?.response && err.response.status === 401) {
            manualLogout();
          } else if (
            err &&
            err?.response &&
            err?.response?.data?.message?.order_no
          ) {
            const message = err?.response?.data?.message?.order_no[0];
            toast.error(message || "Unable to cancel order!", {
              position: toast.POSITION.BOTTOM_RIGHT,
            });
          } else {
            toast.error(
              err.response.data.message || "Unable to cancel order!",
              {
                position: toast.POSITION.BOTTOM_RIGHT,
              }
            );
          }
        });
    }
  };
  const handleCompleteOrder = () => {
    setIsLoading(true);
    setIsPaid(true);

    // First ensure splitBillFinalAmount is properly calculated
    const finalPaidAmount =
      orderSplits?.length > 0
        ? parseFloat(splitBillFinalAmount) || parseFloat(amount) // Use parseFloat for decimal values
        : parseFloat(amount);

    console.log("Final Paid Amount:", finalPaidAmount); // Debug log

    const dineInBody = {
      restaurant_id: restaurantId,
      location_id: locationId,
      status: "complete",
      order_no: checkoutOrder?.order_no,
      tender_id: checkoutTender && checkoutTender?.id,
      receipt_amount: getTotalPrice(),
      paid_amount: finalPaidAmount, // Use the calculated amount
      return_change: Number(Number(return_change).toFixed(8)),
      pos_pin,
    };

    // Log the body before sending
    console.log("Request Body:", dineInBody);

    axios
      .post("/order/status", dineInBody, {
        headers: {
          Authorization: `Bearer ${token}`,
          Location: locationId,
        },
      })
      .then((res) => {
        setIsLoading(false);
        toast.success(
          res.data.message ||
            `${checkoutOrder?.type} order ${checkoutOrder?.order_no} is completed`,
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );
        setQrCode(res?.data?.data?.qr_code);
        setTimeout(() => {
          handlePrient();
        }, 1000);

        setTimeout(() => {
          navigate("/orders/all");
        }, 2500);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err && err?.response && err.response.status === 401) {
          manualLogout();
        } else {
          toast.error(
            err?.response?.data?.message ||
              "An error occurred while completing your order!",
            {
              position: toast.POSITION.BOTTOM_RIGHT,
            }
          );
        }
      });
  };

  useEffect(() => {
    if (selectedOrders) {
      // setOrderTypes(selectedOrders);
      setActiveTab(selectedOrders[0]);
    }
  }, [selectedOrders]);

  const handleOrderPrintingRecord = () => {
    OrderServices.printOrderReceipt(
      { order_id: checkoutOrder?.id },
      (res) => {
        console.log("Res", res);
      },
      (err) => {
        console.log("Error", err);
      }
    );
  };

  const handlePrient = useReactToPrint({
    content: () => receiptRef.current,
    onAfterPrint: handleOrderPrintingRecord,
  });

// Add these debug logs
// console.log('Tender Debug:', {
//   showTenderTax,
//   tenderFee,
//   checkoutTender,
//   tax: checkoutTender?.tax,
//   tax_type: checkoutTender?.tax_type,
//   tax_value: checkoutTender?.tender_tax,
//   order_total: getSubTotalPrice()
// });

  return (
    <Fragment>
      <div
        className="pl-10 mb-10 hide-content"
        onClick={() => navigate("/orders/all")}
      >
        <p className="text-semibold font-Inter underline cursor-pointer text-lg text-primary">
          Return to order screen
        </p>
      </div>
      <div className={classes.ReceiptPage}>
        <LoadingScreen show={isLoading} />

        {showDrawer && (
          <PrimaryDrawerCart
            onProceed={onProceedCart}
            onEdit={onEditCart}
            toggleDrawer={toggleDrawer}
            order={editingOrder}
            clearAll={clearAll}
          />
        )}
        <CancelOrderModal
          showModal={showCancelModal}
          toggleModal={toggleCancelModal}
          value={orderCancelReason}
          onChange={(val) => setOrderCancelReason(val)}
          placeholder="Give us your reason for cancelling order"
          selectedChannel={checkoutChannel && checkoutChannel}
          onProceed={() => {
            handleCancelOrder();
          }}
          error={errorReason.error}
          errorText={errorReason.errorText}
          onFocus={() => setErrorReason({ error: false, errorText: "" })}
        />
        {/* Change calculator modal */}
        <CheckoutReceiptChangeModal
          toggleModal={() => toggleOrderChangeModal(!orderChangeModal)}
          showModal={orderChangeModal}
          orderTotal={getTotalPrice()?.toFixed(decimal_preference)}
          completeOrder={handleCompleteOrder}
          amount={amount}
          changeUserAmount={(val) => setAmount(val)}
        />

        <div className={`${classes.CartBox} hide-content`}>
          <div className={classes.Cart}>
            <div className={classes.CartHeader}>
              <p>
                Order ID <span className="">#{checkoutOrder?.order_no}</span>
              </p>
              {checkoutChannel && checkoutChannel?.img && showChannel && (
                <img
                  src={checkoutChannel?.img}
                  alt={`${checkoutChannel?.name} logo`}
                />
              )}
            </div>

            <p className="text-greyText text-lg font-Inter font-medium text-end">
              {moment().format("hh:mm:ss a DD/MM/yyyy")}
            </p>
            {/* <div className={classes.OrderPersons}> */}
            {checkoutOrder?.type === "dine in" && (
              <p className="text-greyText text-lg font-Inter font-medium text-end pt-1 pb-1">
                {checkoutOrder?.client_name}
              </p>
            )}

            {/* </div> */}
            {checkoutOrder?.type === "dine in" && (
              <p className={classes.OrderTableText}>
                Table - {checkoutOrder?.outletTable?.table_number}{" "}
              </p>
            )}
            <div className={classes.OrderTypesAndDate}>
              {Object.keys(cartState.cart).length > 0 && (
                <div
                  // className={classes.CartTypeButtons}
                  className="w-full grid grid-cols-2 lg:grid-cols-3 gap-5 pt-4 border-t-[0.2px] border-b-[0.2px] border-t-gray-200 border-b-gray-200 pb-4"
                >
                  {[checkoutOrder && checkoutOrder.type]?.map((type) => {
                    return (
                      <SingleOrderType
                        key={type}
                        textLg1={type}
                        value={type}
                        textLg2={servicesTranslations?.ar[type] || ""}
                        name="cartType"
                        isChecked={cartState.activeCart === type}
                        onChanged={radioChangeHandler}
                        totalCartPrice={getActiveTabTotalPrice(type)}
                        active={activeOrder}
                        // key={type?.type}
                        // textLg1={type.type}
                        // value={type.type}
                        // textLg2={servicesTranslations?.ar[type.type] || ""}
                        // isChecked={cartState?.activeCart === type?.type}
                        // onChanged={(val) => {
                        //   setActiveTab(type);
                        //   radioChangeHandler(val);
                        // }}
                        // totalCartPrice={getActiveTabTotalPrice(type?.type)}
                        // active={cartState?.activeCart}
                      />
                    );
                  })}
                </div>
              )}
            </div>

            <ul className={classes.ItemList}>
              {Object.keys(cartState?.cart).length > 0 &&
                cartState?.cart[cartState?.activeCart]?.map((item, index) => {
                  return (
                    <SingleListItem
                      item={item}
                      key={Math.random(100)}
                      id={item.item_id}
                      index={index + 1}
                      quantity={item.quantity}
                      price={item.price + item.vat}
                      sizes={item?.sizes}
                      translations={item?.translations}
                      name={item?.item_name}
                    />
                  );
                })}
            </ul>
            <br />
            <div className={classes.PaymentTab}>
              <Payment
                text={t("Price without tax")}
                price={getSubTotalPrice()}
              />
              {currentLocation?.taxes?.length > 0 && currentLocation?.taxes?.map((tax) => {
                const taxableAmount = getSubTotalPrice();
                const taxAmount = (taxableAmount * tax.tax_value) / 100;
                
                return (
                  <Payment 
                    key={tax.id}
                    text={t(`${tax.translations.en.name} (${tax.tax_value}٪)`)} 
                    price={Number(taxAmount.toFixed(decimal_preference))} 
                  />
                );
              })}
              <Payment
                text={t("SUBTOTAL")}
                price={Number((getSubTotalPrice() + getTotalTaxAmount()).toFixed(decimal_preference))}
              />
              {orderSplits &&
                orderSplits.length > 0 &&
                orderSplits.map((split, index) => {
                  // Calculate tender tax based on tax type
                  let tenderTaxAmount = 0;
                  if (split.tender_tax_type?.toLowerCase() === "percentage") {
                    tenderTaxAmount =
                      (Number(split.final_amount) *
                        Number(split.tender_tax_value)) /
                      100;
                  } else if (split.tender_tax_type?.toLowerCase() === "fixed") {
                    tenderTaxAmount = Number(split.tender_tax_applied);
                  }

                  return (
                    <Payment
                      key={index}
                      text={
                        orderSplits?.length > 0
                          ? t(`Tender tax (${split.tender_name})`)
                          : ""
                      }
                      price={Number(split.tender_tax_applied)}
                    />
                  );
                })}
              {showChannel && (
                <Payment
                  text={
                    checkoutChannel &&
                    checkoutChannel?.commission_type === "percentage"
                      ? t(`Channel Fee (${checkoutChannel?.commission}٪)`)
                      : t(`Channel Fee (${checkoutChannel?.commission})`)
                  }
                  price={getChannelPrice()}
                />
              )}
                  {showTenderTax && (
                <Payment
                  text={
                    checkoutTender && checkoutTender?.tax_type === "percentage"
                      ? t(`Tender (${checkoutTender?.tax}٪)`)
                      : t(`Tender (${checkoutTender?.tax})`)
                  }
                  price={tenderFee}
                />
              )}
              {showDiscountReceipt && (
                <>
                  {discountTypeReceipt === "percentage" ? (
                    <Payment
                      text={t(`Discount`)}
                      price={discountPriceReceipt()}
                    />
                  ) : (
                    <Payment
                      text={t(`Discount`)}
                      price={discountPriceReceipt()}
                    />
                  )}
                </>
              )}

              <Payment
                text={t("PAYABLE_AMOUNT")}
                price={
                  orderSplits?.length > 0
                    ? splitBillFinalAmount
                    : getTotalPrice()
                }
                size="large"
              />
            </div>
          </div>

          <div className={classes.CartActions}>
            {checkoutOrder?.status !== "cancel" && canCancelOrder && (
              <button onClick={() => toggleCancelModal()}>
                <Icon name="cross" />
                Cancel Order
              </button>
            )}

            {canUpdateOrder && (
              <button onClick={() => showOrder()}>
                <Icon name="modify" />
                Modify Order
              </button>
            )}
          </div>

          <div></div>
          {orderSplits.length > 0 && (
            <div className={classes.SplitReceiptsContainer}>
              {orderSplits.map((split) => (
                <div key={split.id}>
                  <SplitReceipt
                    split={split}
                    totalAmount={splitBillFinalAmount}
                    activeTab={activeTab}
                    getSubTotalPrice={getSubTotalPrice}
                    getChannelPrice={getChannelPrice}
                    getTotalPrice={getTotalPrice}
                    isPaid={isPaid}
                    showChannel={showChannel}
                    showTax={taxEnabled}
                    showDiscount={showDiscountReceipt}
                    getDiscount={discountPriceReceipt}
                    qrCode={qrCode}
                    isDuplicate={false}
                    showTenderTax={showTenderTax}
                    tenderFee={tenderFee}
                  />
                </div>
              ))}
            </div>
          )}
        </div>

        {/* Receipt View */}
        <div className={classes.ReceiptBox} id="receipt-to-print">
          <div className={`${classes.ReceiptLanguageSwitcher} hide-content`}>
            <span>Preview</span>
            {/* <button
              onClick={() => setReceiptLang("en")}
              className={
                receiptLang === "en"
                  ? classes.ReceiptLanguageSwitcherBtnSelected
                  : classes.ReceiptLanguageSwitcherBtn
              }
            >
              English
            </button>
            <button
              onClick={() => setReceiptLang("ar")}
              className={
                receiptLang === "ar"
                  ? classes.ReceiptLanguageSwitcherBtnSelected
                  : classes.ReceiptLanguageSwitcherBtn
              }
            >
              عربى
            </button> */}
          </div>
          <div ref={receiptRef}>
            {/* Receipt */}
            {/* <div> */}
            <MainReceipt
              activeTab={activeTab}
              splitBillFinalAmount={splitBillFinalAmount}
              orderSplits={orderSplits}
              getSubTotalPrice={getSubTotalPrice}
              getChannelPrice={getChannelPrice}
              getTotalPrice={getTotalPrice}
              isPaid={isPaid}
              showChannel={showChannel}
              showTax={taxEnabled}
              showDiscount={showDiscountReceipt}
              getDiscount={discountPriceReceipt}
              qrCode={qrCode}
              isDuplicate={false}
              showTenderTax={showTenderTax}
              tenderFee={tenderFee}
            />
            {/* </div> */}
          </div>
          <span className="hide-content">
            {checkoutOrder?.status === "draft" && (
              <IconButton
                icon="tick-circle"
                iconPosition="left"
                text={
                  checkoutTender?.name === "cash"
                    ? "Confirm Order"
                    : `Confirm ${checkoutOrder?.type} Order & Print Receipt`
                }
                customClasses={classes.ConfirmAndPrintBtn}
                onClicked={
                  checkoutTender?.name === "cash"
                    ? () => {
                        setIsPaid(true);
                        toggleOrderChangeModal(true);
                      }
                    : () => {
                        setIsPaid(true);
                        setTimeout(() => {
                          handleCompleteOrder();
                        }, 900);
                      }
                }
              />
            )}
            {/* {selectedOrders?.length > 1 && (
            <IconButton
              icon="tick-circle"
              iconPosition="left"
              text={`Confirm all Orders `}
              customClasses={classes.ConfirmAndPrintBtn}
              onClicked={() => handleCompleteAllOrder()}
            />
          )} */}

            <IconButton
              icon="print"
              iconPosition="left"
              text="Print Receipt Only"
              customClasses={classes.PrintBtn}
              onClicked={() => {
                setIsPaid(false);
                setTimeout(() => {
                  handlePrient();
                }, 1000);
              }}
            />
          </span>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default ReceiptPage;
