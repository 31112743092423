import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  order: null,
  selectedOrders: [],
  activeService: null,
  checkoutOrder: {},
  deliverectOrder: {},
  orderTaxes: {},
  pos_pin: null,
};

const editOrderSlice = createSlice({
  name: "editOrder",
  initialState,
  reducers: {
    addEditingOrder(state, action) {
      state.order = action.payload;
    },
    addSelectedOrder(state, action) {
      state.selectedOrders = action.payload;
      // console.log(state.selectedOrders, "Added successfully");
    },
    changeActiveService(state, action) {
      state.activeService = action.payload;
    },
    setVerificationPin(state, action) {
      state.pos_pin = action.payload;
    },
    // Saving the other taxes
    saveOrderTaxes(state, action) {
      const { service, value } = action.payload;
      state.orderTaxes[service] = value;
    },
    removeSelectedOrders(state) {
      state.selectedOrders = [];
      state.activeService = null;
      state.checkoutOrder = null;
      state.deliverectOrder = null;
      state.pos_pin = null;
      state.orderTaxes = {};
    },
    updateDiscountInPrimaryDrawerCart(state, { payload }) {
      const { discount, discount_type, type } = payload;
      const newOrders = [...state.selectedOrders].map((item) => {
        if (item.type === type) {
          item.discount_value = discount;
          item.discount_type = discount_type;
        }
        return item;
      });
      state.selectedOrders = [...newOrders];
    },
    updateSelectedOrders(state, action) {
      const data = action.payload;
      const isContained = state.selectedOrders.filter(
        (item) => item.type === data.type
      );
      if (isContained.length > 0) {
        state.selectedOrders = [
          ...state.selectedOrders.filter((el) => el.type !== data.type),
        ];
      } else {
        state.selectedOrders = [...state.selectedOrders, action.payload];
      }
    },
    initialUpdateDiscountType(state, { payload }) {
      const { type, discount_type } = payload;
      const oldOrders = [...state.selectedOrders];
      oldOrders.forEach((el) => {
        if (el.type === type) {
          el.discount_type = discount_type;
        }
      });
      state.selectedOrders = [...oldOrders];
    },
    updateSelectedOrdersForDiscount(state, { payload }) {
      const { type, discount_value, discount_type } = payload;
      const oldOrders = [...state.selectedOrders];
      oldOrders.forEach((el) => {
        if (el.type === type) {
          el.discount_type = discount_type;
          el.discount_value = discount_value;
        }
      });
      state.selectedOrders = [...oldOrders];
    },
    updateSelectedOrdersForOrderNote(state, { payload }) {
      const { type, order_note } = payload;
      const oldOrders = [...state.selectedOrders];
      oldOrders.forEach((el) => {
        if (el.type === type) {
          el.comment = order_note;
        }
      });
      state.selectedOrders = [...oldOrders];
    },
    toggleActiveCheckoutOrder(state, action) {
      state.checkoutOrder = action.payload;
    },
    saveDeliverectOrder(state, { payload }) {
      state.deliverectOrder = payload;
    },
  },
});

export const {
  addEditingOrder,
  addSelectedOrder,
  removeSelectedOrders,
  updateSelectedOrders,
  changeActiveService,
  setVerificationPin,
  updateSelectedOrdersForDiscount,
  updateSelectedOrdersForOrderNote,
  initialUpdateDiscountType,
  toggleActiveCheckoutOrder,
  saveDeliverectOrder,
  updateDiscountInPrimaryDrawerCart,
  saveOrderTaxes,
} = editOrderSlice.actions;
export default editOrderSlice.reducer;
